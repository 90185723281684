import { DependencyProviderService } from './dependency-provider.service';

export function Traceable() {
  return (target: any, name?: string, descriptor?: any) => {    

    if (descriptor) {
      
      // decorating a method
      let original = descriptor.value;

      if (original.untrace === true) {
        return descriptor;
      }

      descriptor.value = function (...args: any[]) {

        let argString: string = "";
         
        if (original.untraceParameters === true) {
          argString = "...";
        }
        else {
          argString = args.map(function (value) { return JSON.stringify(value) }).join(", ");
        }

        DependencyProviderService.log.traceIn(`${target.constructor.name}.${name}(${argString})`);
        let initTime = Date.now();
        let result = original.apply(this, args);
        
        if (result instanceof Promise){                        
          return result.then(value =>{ 
            DependencyProviderService.log.traceOut(`${target.constructor.name}.${name} ==> (async) ${(value != undefined ? JSON.stringify(value) : 'void')} <${Date.now() - initTime}ms>`);
            return value;
          });
        }
        else{            
          DependencyProviderService.log.traceOut(`${target.constructor.name}.${name} ==> ${(result != undefined ? JSON.stringify(result) : 'void')} <${Date.now() - initTime}ms>`);
          return result;
        }            
      }

      return descriptor;

    } else {

      // decorating a class
      // add tracing capability to all own methods
      // TO-DO doesn't work for constructor...
      Object.getOwnPropertyNames(target.prototype).forEach((methodName: string) => {

        let original = target.prototype[methodName];

        if (typeof original !== "function" || methodName === "constructor" || original.untrace === true) {
          return;
        }

        // an arrow function can't be used while we have to preserve right 'this'
        target.prototype[methodName] = function (...args: any[]) {
          let argString: string = "";

          if (original.untraceParameters === true) {
            argString = "...";
          }
          else {
            argString = args.map(function (value) { return JSON.stringify(value) }).join(", ");
          }

          DependencyProviderService.log.traceIn(`${target.name}.${methodName}(${argString})`);
          let initTime = Date.now();
          let result = original.apply(this, args);

          if (result instanceof Promise){                        
            return result.then(value =>{ 
              DependencyProviderService.log.traceOut(`${target.name}.${methodName} ==> (async) ${(value != undefined ? JSON.stringify(value) : 'void')} <${Date.now() - initTime}ms>`);
              return value;
            });
          }
          else{            
            DependencyProviderService.log.traceOut(`${target.name}.${methodName} ==> ${(result != undefined ? JSON.stringify(result) : 'void')} <${Date.now() - initTime}ms>`);
            return result;
          }                  
        };
      });

      return target;
    }
  };
}

export function Untrace() {
  return (target: any, name?: string, descriptor?: any) => {
    if (descriptor) {
      descriptor.value.untrace = true;
    }
  }
}

export function UntraceParameters() {
  return (target: any, name?: string, descriptor?: any) => {
    if (descriptor) {
      descriptor.value.untraceParameters = true;
    }
  }
}
