import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LogService } from '../diagnostic/log.service';
import { PrincipalService } from './principal.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationHeaderFactory {

  constructor(
    private principal:PrincipalService,
    private log: LogService
  ) { }

  getHeader() : string{
    let authorizationHeader:string;

    if (this.principal.isAuthenticated || this.principal.hasAuthCookie) {
      //this.log.information("Bearer Authentication");

      authorizationHeader = "Bearer " + this.principal.accessToken;
    }
    else {
      //this.log.information("Basic Authentication");

      authorizationHeader = "Basic " + this.getBasicCredentials();
    }

    return authorizationHeader;
  }

  private getBasicCredentials(): string {
    return btoa(environment.api.user + ":" + environment.api.password);
  }
}
