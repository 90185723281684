import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LogService } from '../diagnostic/log.service';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loader: Promise<HTMLIonLoadingElement>;
  showTimeout: any;
  wasShown: boolean = false;
  showCount: number = 0;

  constructor(
    private loadingController: LoadingController,
    private log: LogService) { }

  async init() {
    console.log("loading Init");
    this.loader = this.loadingController.create();
    console.log("loading Init End");
  }

  async show() {
    this.showCount++;
    this.log.debug(`Show (${this.showCount})`);

    if (this.showCount == 1) {
      this.wasShown = false;
      this.showTimeout = setTimeout(async () => {
        this.wasShown = true;
          this.log.debug("Showed");
        await this.loader.then(loader => loader.present());
      }, 200);
    }
  }

  hide() {
    this.log.debug(`Hide (${this.showCount})`);
    this.showCount--;

    if (this.showCount == 0) {

      if (this.wasShown){
        this.log.debug(`Hided`);
        this.loader.then(loader => loader.dismiss());
        this.init();
      }
      else{
        this.log.debug(`Cleared`);
        clearTimeout(this.showTimeout);
      }
    }
  }
}
