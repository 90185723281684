import { Injectable } from '@angular/core';
import { isNullorEmpty } from 'src/app/common';
import { LogService } from '../diagnostic/log.service';
import { PermanentStorageService } from '../storage/permanentstorage.service';

@Injectable({
  providedIn: 'root'
})
export class GpsService {
  readonly PERMISSION_DENIED: number = 1;
  readonly POSITION_UNAVAILABLE: number = 2;
  readonly TIMEOUT: number = 3;

  constructor(private storage: PermanentStorageService,
    private log: LogService) { }

  setSettings(minimunAccuracy, maxFixingTime, timeout) {
    this.storage.gpsMinimunAccuracy = minimunAccuracy;
    this.storage.gpsMaxFixingTime = maxFixingTime;
    this.storage.gpsTimeout = timeout;
  }

  public async getPosition(byPassMinimunAcurracy: boolean = false) {
    this.log.debug('Using GetWebPosition');
    return new Promise<any>((resolve, reject) => {
      let options = {
        enableHighAccuracy: true,
        timeout: this.storage.gpsTimeout * 1000,
        maximumAge: 10000
      }

      let timeOut = null;

      let previousPosition = null;

      let subscription = navigator.geolocation.watchPosition(position => {
        //por alguna razón el error se se envía como parámetro aca y no en la parte de error del subscriber
        if (isNullorEmpty(position.coords)) {
          clearTimeout(timeOut);
          navigator.geolocation.clearWatch(subscription);
          if (!isNullorEmpty(previousPosition)) {
            this.log.event("LocationFound");
            resolve(previousPosition);
          }
          else {
            reject(position);
          }
          return;
        }
        this.log.debug(`GPS Location Found! Acc ${position.coords.accuracy}, Lat ${position.coords.longitude}, Long ${position.coords.latitude}`);

        if (position.coords.accuracy < this.storage.gpsMinimunAccuracy || byPassMinimunAcurracy) {
          clearTimeout(timeOut);
          navigator.geolocation.clearWatch(subscription);
          resolve(position);
        } else {
          this.log.debug("More accuracy is needed");

          if (isNullorEmpty(timeOut)) {
            timeOut = setTimeout(() => {
              this.log.debug("Maximum fixed time was reached");

              navigator.geolocation.clearWatch(subscription);
              if (!isNullorEmpty(previousPosition)) {
                this.log.event("LocationFound");
                resolve(previousPosition);
              }
              else {
                reject(Error("TimeOut"));
              }
            }, this.storage.gpsMaxFixingTime * 1000);
          }

          if (isNullorEmpty(previousPosition) || previousPosition.coords.accuracy > position.coords.accuracy) {
            this.log.debug("Better position was found");
            previousPosition = position;
          }
        }
      },
        error => {
          clearTimeout(timeOut);
          navigator.geolocation.clearWatch(subscription);
          if (!isNullorEmpty(previousPosition)) {
            this.log.event("LocationFound");
            resolve(previousPosition);
          }
          else {
            reject(error);
          }
        }, options);
    });
  }
}
