import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const appVersion = "1.8.1-pdf14.dev.1";

export enum Platforms {
  Cordova = 'cordova',
  Android = 'android',
  IOS = 'ios',
  PWA = 'pwa',
  Mobile = 'mobile',
  MobileBrowser = 'mobileweb'
}
  
  export function isNullorEmpty(object) {
    return object == null || object == "null" || object === "" || object == "undefined";
  }

  export enum Patterns {
    Email = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$",
  }

  export class Errors extends Error {
    messages: string[];
  
    constructor(messages: string[]) {
      if (messages.length == 0) {
        console.log("m0");
        super();
      }
      else if (messages.length == 1) {
        console.log("m1");
        super(messages[0]);
      } else {
        console.log("mn");
        super("Multiple error messages");
      }
      console.log(messages.length);
      console.log(messages);
      this.messages = messages;
    }
  
  }

  export function matchValidator(controlName: string, referenceControlName: string): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      if (control.value[controlName] != control.value[referenceControlName]) {
        control.controls[controlName].setErrors({ 'mismatch': true });
        return { 'mismatch': true };
      }
      else {
        //TODO: Eliminar solo el error 'mismatch'
        control.controls[controlName].setErrors(null);
      }

      return null;
    }
  }

  export const modalState = {
    modal: true,
    desc: 'fake state for our modal'
  };

  export function hideSplashScreen() : boolean{
    const splashScreen: HTMLElement = document.getElementById('splashscreen');
    if (splashScreen) {
      splashScreen.classList.add("hide");
      setTimeout(() => {
        splashScreen.remove();
      }, 300);
      return true;
    }

    return false;
  }

  export enum BehaviorModes{
    Table,
    Express
  }

  export function getGoogleMaps(apiKey: string): Promise<any> {
    const win = window as any;
    const googleModule = win.google;
    if (googleModule && googleModule.maps) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.onload = () => {
        const googleModule2 = win.google;
        if (googleModule2 && googleModule2.maps) {
          resolve();
        } else {
          reject('google maps not available');
        }
      };
    });
  }