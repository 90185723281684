import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { isNullorEmpty } from 'src/app/common';
import { Settings } from '../api/entities';
import { ProxyService } from '../api/proxy.service';
import { LogService } from '../diagnostic/log.service';
import { PermanentStorageService } from '../storage/permanentstorage.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  _isOffLine: boolean = false;

  constructor(
    private storage: PermanentStorageService,
    private proxy: ProxyService,
    private log: LogService) { }

  setSettings(isGpsRequired: boolean) {
    this.storage.isGpsRequired = isGpsRequired
  }

  get id() {
    return this.storage.deviceId;
  }

  get hasId() {
    return !isNullorEmpty(this.id);
  }

  get isGpsRequired() {
    return this.storage.isGpsRequired;
  }

  get isOffLine() {
    return this._isOffLine;
  }

  async register() : Promise<Settings>{
    let initTime = Date.now();    
    let registration = await this.proxy.Register(await this.DeviceUniqueIdentificator());

    this.storage.deviceId = registration.Id;

    if (this.storage.BrowserGuid != registration.Code){
      let previousGuid = this.storage.BrowserGuid;
      this.storage.BrowserGuid = registration.Code;
      this.log.warning(`Replacing BrowserGuid from ${previousGuid} to ${registration.Code}`);      
    }

    this.log.event("RegisterStart", initTime);
    this.log.event("Registered");

    return registration.Settings;
  }

  makeOffLine() {
    this._isOffLine = true;
  }

  makeOnLine() {
    this._isOffLine = false;
  }

  private async DeviceUniqueIdentificator(): Promise<string> {
    if (isNullorEmpty(this.storage.BrowserGuid)) {
      this.log.debug("Using Guid to get Id");
      this.storage.BrowserGuid = Guid.create().toString();
    }

    return this.storage.BrowserGuid;
  }

  async logInformation() {
    let deviceInfo: string = "";

    deviceInfo += "availHeight: " + window.screen.availHeight + " | ";
    deviceInfo += "availWidth: " + window.screen.availWidth + " | ";
    deviceInfo += "colorDepth: " + window.screen.colorDepth + " | ";
    deviceInfo += "height: " + window.screen.height + " | ";
    deviceInfo += "pixelDepth: " + window.screen.pixelDepth + " | ";
    deviceInfo += "width: " + window.screen.width + " | ";
    deviceInfo += "devicePixelRatio: " + window.devicePixelRatio;

    this.log.information(deviceInfo);

    this.log.information(navigator.userAgent);
  }

}
