<ion-app [ngClass]="appClass">
    <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>
    <!-- Se agregan los componentes para precargarlos, el modal, alert y toast se precarga en el app.component (modal de Login) -->
    <ion-segment style="display: none;"></ion-segment>
    <ion-text style="display: none;"></ion-text>
    <ion-textarea style="display: none;"></ion-textarea>
    <ion-card style="display: none;"></ion-card>
    <ion-col style="display: none;"></ion-col>
    <ion-toggle style="display: none;"></ion-toggle>
    <ion-input style="display: none;"></ion-input>
    <ion-fab style="display: none;"></ion-fab>
    <ion-searchbar style="display: none;"></ion-searchbar>
    <ion-slide style="display: none;"></ion-slide>
    <ion-avatar style="display: none;"></ion-avatar>
    <ion-select style="display: none;"></ion-select>
    <ion-datetime style="display: none;"></ion-datetime>   
    <ion-item-divider style="display: none;"></ion-item-divider> 
    <ion-tab-bar style="display: none;"></ion-tab-bar>
    <ion-img style="display: none;"></ion-img>
    <ion-skeleton-text style="display: none;"></ion-skeleton-text>
</ion-app>
