import { Injectable } from '@angular/core';
import { appVersion, isNullorEmpty } from 'src/app/common';
import { LogLevels, MobileError, MobileEvent, PerfomnaceEvent as PerformanceEvent } from '../api/entities';
import { ProxyService } from '../api/proxy.service';
import { PermanentStorageService } from '../storage/permanentstorage.service';


@Injectable({
  providedIn: 'root'
})
export class LogService {

  private lastLog: number = null;
  private buffer: string = "";
  private events: PerformanceEvent[] = [];
  private flushTimeout: any;
  private autoFlush: boolean = true;
  private firstLog: number;

  constructor(
    private storage: PermanentStorageService,
    private proxy: ProxyService,
  ) {
  }

  setSettings(flushInterval: number, logLevel: LogLevels) {
    this.storage.logLevel = logLevel
    this.storage.flushInterval = flushInterval;
  }

  init()
  {
    this.firstLog = Date.now();
    window['BTInitEvent'] = this.firstLog;
    
    let message = `BTLoadTime: ${window['BTLoadTime']}` + "\n\r";
    message += `Log Init -- ${this.getTime()}` + "\n\r";
    console.log(message);
    this.buffer += message;
  }

  get logLevel() {
    return this.storage.logLevel;
  }

  event(type: string, time: number = Date.now(), details: string = ""){
    
    if (this.logLevel >= LogLevels.Trace) {
      let event = new PerformanceEvent();

      event.Time = time;
      event.DeviceCode = this.storage.BrowserGuid;
      event.Type = type;
      event.Details = details;

      this.events.push(event);

      this.trace("EVENT: " + type);
    }
  }

  debug(message: string) {
    message = `${JSON.stringify(message)} -- ${this.getTime()}`;

    console.log(message);

    if (this.logLevel >= LogLevels.Debug) {
      this.log(message);
    }
  }

  traceIn(message: string) {
    message = `=> ${JSON.stringify(message)} -- ${this.getTime()}`;

    console.log(message);

    if (this.logLevel >= LogLevels.Trace) {
      this.log(message);
    }
  }

  traceOut(message: string) {
    message = `<= ${JSON.stringify(message)} -- ${this.getTime()}`;

    console.log(message);

    if (this.logLevel >= LogLevels.Trace) {
      this.log(message);
    }
  }

  trace(message: string) {
    message = `<> ${JSON.stringify(message)} -- ${this.getTime()}`;

    console.log(message);

    if (this.logLevel >= LogLevels.Trace) {
      this.log(message);
    }
  }

  information(message: string) {
    message = `${JSON.stringify(message)} -- ${this.getTime()}`;

    console.info(message);

    if (this.logLevel >= LogLevels.Information) {
      this.log(message);
    }
  }

  warning(message: any) {

    if (typeof message  === 'string'){
      message = `WARNING: ${JSON.stringify(message)} -- ${this.getTime()}`;
    }
    else{
      message = `WARNING: ${JSON.stringify(message)} (${message.name} : ${message.message} : ${message.stack}) -- ${this.getTime()}`;
    }

    this.event("Warning", Date.now(), message);
    console.warn(message);

    if (this.logLevel >= LogLevels.Warning) {
      this.log(message);
    }
  }

  error(error) {
    let message = `ERROR: ${JSON.stringify(error)} (${error.name} : ${error.message} : ${error.stack}) -- ${this.getTime()}`;

    this.event("Error", Date.now(), message);
    console.error(message);

    if (this.logLevel >= LogLevels.Error) {
      this.flush();

      let error: MobileError = new MobileError();

      //HACK se utiliza el storage en vez del Device Service, ya que da problemas de referencia cíclica
      error.DeviceId = this.storage.deviceId
      error.Version = appVersion;
      error.Error = message;

      this.autoFlush = false;
      this.proxy.LogError(error).subscribe(() => { }, error => { console.error(error) }, () => { this.autoFlush = true; });

      this.log(message);
    }
  }

  critical(error, rejection) {
    let message = "";

    if (!isNullorEmpty(rejection)) {
      message += `CRITICAL: ${JSON.stringify(rejection)} [${rejection.code} : ${rejection.name} : ${rejection.message} : ${rejection.stack}]`;
    }

    message += ` ||| ${JSON.stringify(rejection)} (${error.name} : ${error.message} : ${error.stack}) -- ${this.getTime()}`;

    this.event("Critical", Date.now(), message);
    console.error(message);

    try {
      if (this.logLevel >= LogLevels.Critical) {
        this.flush();

        let error: MobileError = new MobileError();

        //HACK se utiliza el storage en vez del Devise Service, ya que da problemas de referencia cíclica
        error.DeviceId = this.storage.deviceId
        error.Version = appVersion;
        error.Error = message;

        this.autoFlush = false;
        this.proxy.LogError(error).subscribe(() => { }, error => { console.error(error) }, () => { this.autoFlush = true; });

        this.log(message);
      }
    }
    catch (error) {
      console.error(error);
      //TODO: guardar un log para la próxima vez
    }
  }

  private log(message: string) {
    this.buffer += message + "\n\r";

    if (!isNullorEmpty(this.flushTimeout)) {
      clearTimeout(this.flushTimeout);
    }
    
    this.queueFlush();
  }

  private queueFlush(){
    if (this.autoFlush) {
      this.flushTimeout = setTimeout(() => { this.flush() }, this.storage.flushInterval * 1000);
    }
  }

  private getTime(): string {
    let value: string = "";
    let now = Date.now();

    if (this.lastLog == null) {
      value = "[--ms] ";
    }
    else {
      value = `[${(now - this.lastLog)}ms] `;
    }

    value += `{${(now - this.firstLog) / 1000}} `

    this.lastLog = now;

    let curentTime = new Date();

    value += curentTime.toLocaleTimeString([], { hour12: false }) + "." + curentTime.getMilliseconds();

    return value;
  }

  flush() {
    let buffer: string;
    let events: PerformanceEvent[];

    console.log("flush");
    if (!isNullorEmpty(this.buffer)) {
      console.log("save");
      buffer = this.buffer;
      events = this.events;

      this.buffer = "";
      this.events = [];

      let event: MobileEvent = new MobileEvent();

      //HACK se utiliza el storage en vez del Devise Service, ya que da problemas de referencia cíclica
      event.DeviceId = this.storage.deviceId
      event.Event = buffer;
      event.PerformanceEvents = events;
      console.log("flush send");

      this.autoFlush = false;
      let subscription = this.proxy.LogEvent(event)
        .subscribe(() => { }, 
        error => { 
          console.log("flush Error");
          console.error(error); 
          this.buffer = buffer + this.buffer; 
          this.events = this.events.concat(events); }, 
        () => { 
          console.log("flush response");
          subscription.unsubscribe(); 
          this.autoFlush = true; 
          if (this.buffer != ""){
            this.queueFlush();
          }
        });
    }
  }
}
