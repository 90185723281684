import { ErrorHandler, Injectable } from '@angular/core';
import { isNullorEmpty } from 'src/app/common';
import { SessionStorageService } from '../storage/sessionstorage.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private log: LogService, private session: SessionStorageService) { }

  handleError(error: any): void {
    this.log.critical(error, error.rejection);

    //Si da error cargando alguno de los Chunks del WebPack (Lazy Loading), entonces recarga la aplicación
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    //si da error cargando el idioma
    const localizationFailedMessage = /.*\/assets\/i18n\/.*/;

    if (chunkFailedMessage.test(error.message) || localizationFailedMessage.test(error.message)) {
      var reloads: any = this.session.ForcedReloadsCount;

      if (isNullorEmpty(reloads)) {
        reloads = 0;
      }

      if (reloads < 3) {

        reloads += 1;
        this.session.ForcedReloadsCount = reloads;

        this.log.warning(`Forcing Reload: ${reloads}`);
        this.log.flush();
        setTimeout(() => {
          window.location.reload();
        }, 300);
      }
      else{
        this.session.ForcedReloadsCount = 0;
        this.log.error(`Forcing Reload didn't resolve the issue`);
        this.log.flush();
      }
    }
  }  
}
