import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { Contact, DeliveryAddress, LogLevels, Place, Recipient, TableInfo, UserInformation } from '../api/entities';

enum StorageKeys {
  LASTTUTORIALSHOWN = "lasttutorialshown",
  LASTPROMOSHOWN = "lastpromoshown",  /*Cuando se monstro al usuario*/

  //-------------------Device---------------------------
  DeviceIdKey = "BystroKey_DeviceId",
  BrowserGuid = "BystroKey_BrowserGuid",
  IsGpsRequired = "BystroKey_IsGpsRequired",

  //-------------------Table----------------------------
  Table = "BystroKey_Table",
  LastTableAccess = "BystroKey_LastTableAccess",
  IdleTimeout = "BystroKey_IdleTimeout",
  LastWelcome = "BystroKey_LastWelcome",

  //-------------------User-----------------------------
  User = "BystroKey_User",
  AllowGuest = "BystroKey_AllowGuest",
  GuestExpireTime = "BystroKey_GuestExpireTime",
  GuestName = "BystroKey_GuestName",
  GuestStartDate = "BystroKey_GuestStartDate",
  MaximumProductRequestAnonymous = "BystroKey_MaxProductAnonymous",

  //-------------------Api------------------------------
  CurrentAppVersion = "BystroKey_CurrentAppVersion",
  AccessTokenKey = "BystroKey_AccessToken",
  RefreshTokenKey = "BystroKey_RefreshToken",

  //-------------------Logs-----------------------------

  LogLevel = "BystroKey_LogLevel",
  Logs = "BystroKey_Logs",
  FlushInterval = "BystroKey_FlushInterval",

  //-------------------Gps-----------------------------

  GpsMinimunAcurracy = "BystroKey_MinimumGPSAccuracy",
  GpsTimeout = "BystroKey_GpsTimeout",
  GpsMaxFixingTime = "BystroKey_GpsMaxFixingTime",

  //-------------------Place-----------------------------

  Places = "BystroKey_Places",

  //--------------------Cart-----------------------------

  ProductRequestedAnonymousCount = "BystroKey_ProductsAnonymous",
  //------------------Express---------------------------

  Address = "BystroKey_Address",
  DefaultAddressId = "BystroKey_DefaultAddressId",
  Recipient = "BystroKey_Recipient",
  Contact = "BystroKey_Contact",

  //------------------Tutorial---------------------------
  WasMenuTutorialShown = "BystroKey_WasMenuTutorialShown",
  WasCartTutorialShown = "BystroKey_WasCartTutorialShown",
  WasScannerTutorialShown = "BystroKey_WasScannerTutorialShown",

  //------------------Localization-----------------------
  LastLocalizationCode = "BystroKey_LastLocalizationCode",
  WasLanguagePickerShown = "BystroKey_WasLanguagePickerShown"
}

enum NativeStorageErrorCodes {
  NATIVE_WRITE_FAILED = 1,
  ITEM_NOT_FOUND = 2,
  NULL_REFERENCE = 3,
  UNDEFINED_TYPE = 4,
  JSON_ERROR = 5,
  WRONG_PARAMETER = 6,
}

@Injectable({
  providedIn: 'root'
})
export class PermanentStorageService {

  private _deviceId: number;
  private _browserGuid: string;
  private _isGpsRequired: boolean;

  private _table: TableInfo;
  private _lastTableAccess: number;
  private _idleTimeout: number;
  private _lastWelcome: WelcomeRegister[];

  private _user: UserInformation;
  private _allowGuest: boolean;
  private _guestName: string;
  private _guestExpireTime: number;
  private _guestStartDate: number;
  private _maximumProductRequestAnonymous: number;

  private _currentAppVersion: string;
  private _accessToken: string;
  private _refreshToken: string;

  private _logLevel: LogLevels
  private _logs: string;
  private _flushInterval: number;

  private _gpsMinimunAccuracy: number;
  private _gpsTimeout: number;
  private _gpsMaxFixingTime: number;

  private _places: Place[];

  private _productRequestedAnonymousCount: number;

  private _address: DeliveryAddress[];
  private _defaultAddressId: number;
  private _recipient: Recipient;
  private _contact: Contact;

  private _wasMenuTutorialShown: boolean;
  private _wasCartTutorialShown: boolean;
  private _wasScannerTutorialShown: boolean;

  private _lastLocalizationCode: string;
  private _wasLanguagePickerShown: boolean;

  constructor(
    private storage: Storage) { }

  async init() {
    console.log("storage Init");
    await this.getValue(StorageKeys.DeviceIdKey).then(value => { this._deviceId = value });
    await this.getValue(StorageKeys.BrowserGuid).then(value => { this._browserGuid = value });
    await this.getValue(StorageKeys.IsGpsRequired).then(value => { this._isGpsRequired = value || false });

    await this.getValue(StorageKeys.Table).then(value => { this._table = value });
    await this.getValue(StorageKeys.LastTableAccess).then(value => { this._lastTableAccess = value });
    await this.getValue(StorageKeys.IdleTimeout).then(value => { this._idleTimeout = value || 1 * 60 * 60 });
    await this.getValue(StorageKeys.LastWelcome).then(value => { this._lastWelcome = value || [] });

    await this.getValue(StorageKeys.User).then(value => { this._user = value });
    await this.getValue(StorageKeys.AllowGuest).then(value => { this._allowGuest = value || true });
    await this.getValue(StorageKeys.GuestExpireTime).then(value => { this._guestExpireTime = value || 3 * 24 * 60 * 60 });
    await this.getValue(StorageKeys.GuestName).then(value => { this._guestName = value });
    await this.getValue(StorageKeys.GuestStartDate).then(value => { this._guestStartDate = value });
    await this.getValue(StorageKeys.MaximumProductRequestAnonymous).then(value => { this._maximumProductRequestAnonymous = value || 3 });

    await this.getValue(StorageKeys.CurrentAppVersion).then(value => { this._currentAppVersion = value });
    await this.getValue(StorageKeys.AccessTokenKey).then(value => { this._accessToken = value });
    await this.getValue(StorageKeys.RefreshTokenKey).then(value => { this._refreshToken = value });

    await this.getValue(StorageKeys.LogLevel).then(value => { this._logLevel = value || LogLevels.Debug });
    await this.getValue(StorageKeys.Logs).then(value => { this._logs = value });
    await this.getValue(StorageKeys.FlushInterval).then(value => { this._flushInterval = value || 1 });

    await this.getValue(StorageKeys.GpsMinimunAcurracy).then(value => { this._gpsMinimunAccuracy = value || 500 });
    await this.getValue(StorageKeys.GpsTimeout).then(value => { this._gpsTimeout = value || 20 });
    await this.getValue(StorageKeys.GpsMaxFixingTime).then(value => { this._gpsMaxFixingTime = value || 5 });

    await this.getValue(StorageKeys.Places).then(value => { this._places = value });

    await this.getValue(StorageKeys.ProductRequestedAnonymousCount).then(value => { this._productRequestedAnonymousCount = value || 0 });

    await this.getValue(StorageKeys.Address).then(value => { this._address = value || [] });
    await this.getValue(StorageKeys.DefaultAddressId).then(value => { this._defaultAddressId = value });
    await this.getValue(StorageKeys.Recipient).then(value => { this._recipient = value });
    await this.getValue(StorageKeys.Contact).then(value => { this._contact = value });

    await this.getValue(StorageKeys.WasMenuTutorialShown).then(value => { this._wasMenuTutorialShown = value || false });
    await this.getValue(StorageKeys.WasCartTutorialShown).then(value => { this._wasCartTutorialShown = value || false });
    await this.getValue(StorageKeys.WasScannerTutorialShown).then(value => { this._wasScannerTutorialShown = value || false });

    await this.getValue(StorageKeys.LastLocalizationCode).then(value => { this._lastLocalizationCode = value || 'es' });
    await this.getValue(StorageKeys.WasLanguagePickerShown).then(value => { this._wasLanguagePickerShown = value || false });

    console.log("storage Init End");
  }


  get deviceId(): number {
    return this._deviceId;
  }

  set deviceId(value: number) {
    this._deviceId = value;

    this.setValue(StorageKeys.DeviceIdKey, value)
  }

  get BrowserGuid(): string {
    return this._browserGuid;
  }

  set BrowserGuid(value: string) {
    this._browserGuid = value;

    this.setValue(StorageKeys.BrowserGuid, value)
  }

  get isGpsRequired(): boolean {
    return this._isGpsRequired;
  }

  set isGpsRequired(value: boolean) {
    this._isGpsRequired = value;

    this.setValue(StorageKeys.IsGpsRequired, value)
  }

  get table(): TableInfo {
    return this._table
  }

  set table(value: TableInfo) {
    this._table = value;

    this.setValue(StorageKeys.Table, value);
  }

  get lastTableAccess(): number {
    return this._lastTableAccess;
  }

  set lastTableAccess(value: number) {
    this._lastTableAccess = value;

    this.setValue(StorageKeys.LastTableAccess, value);
  }

  get idleTimeout(): number {
    return this._idleTimeout
  }

  set idleTimeout(value) {
    this._idleTimeout = value;

    this.setValue(StorageKeys.IdleTimeout, value);
  }

  get lastWelcome(): WelcomeRegister[] {
    return this._lastWelcome
  }

  set lastWelcome(value: WelcomeRegister[]) {
    this._lastWelcome = value;

    this.setValue(StorageKeys.LastWelcome, value);
  }

  get user() {
    return this._user;
  }

  set user(value) {
    this._user = value;

    this.setValue(StorageKeys.User, value)
  }

  get allowGuest() {
    return this._allowGuest;
  }

  set allowGuest(value) {
    this._allowGuest = value;

    this.setValue(StorageKeys.AllowGuest, value)
  }

  get guestName() {
    return this._guestName;
  }

  set guestName(value) {
    this._guestName = value;

    this.setValue(StorageKeys.GuestName, value)
  }

  get guestExpireTime(): number {
    return this._guestExpireTime;
  }

  set guestExpireTime(value: number) {
    this._guestExpireTime = value;

    this.setValue(StorageKeys.GuestExpireTime, value)
  }

  get guestStartDate(): number {
    return this._guestStartDate;
  }

  set guestStartDate(value: number) {
    this._guestStartDate = value;

    this.setValue(StorageKeys.GuestStartDate, value)
  }

  get maximumProductRequestAnonymous(): number {
    return this._maximumProductRequestAnonymous;
  }

  set maximumProductRequestAnonymous(value: number) {
    this._maximumProductRequestAnonymous = value;

    this.setValue(StorageKeys.MaximumProductRequestAnonymous, value)
  }

  get currentAppVersion(): string {
    return this._currentAppVersion;
  }

  set currentAppVersion(value: string) {
    this._currentAppVersion = value;

    this.setValue(StorageKeys.CurrentAppVersion, value)
  }

  get accessToken(): string {
    return this._accessToken;
  }

  set accessToken(value: string) {
    this._accessToken = value;

    this.setValue(StorageKeys.AccessTokenKey, value)
  }

  get refreshToken(): string {
    return this._refreshToken;
  }

  set refreshToken(value: string) {
    this._refreshToken = value;

    this.setValue(StorageKeys.RefreshTokenKey, value)
  }

  get logLevel(): LogLevels {
    return this._logLevel
  }

  set logLevel(value) {
    this._logLevel = value;

    this.setValue(StorageKeys.LogLevel, value);
  }

  get logs(): string {
    return this._logs
  }

  set logs(value: string) {
    this._logs = value;

    this.setValue(StorageKeys.Logs, value);
  }

  get flushInterval(): number {
    return this._flushInterval
  }

  set flushInterval(value: number) {
    this._flushInterval = value;

    this.setValue(StorageKeys.FlushInterval, value);
  }

  get gpsMinimunAccuracy(): number {
    return this._gpsMinimunAccuracy
  }

  set gpsMinimunAccuracy(value: number) {
    this._gpsMinimunAccuracy = value;

    this.setValue(StorageKeys.GpsMinimunAcurracy, value);
  }

  get gpsTimeout(): number {
    return this._gpsTimeout
  }

  set gpsTimeout(value: number) {
    this._gpsTimeout = value;

    this.setValue(StorageKeys.GpsTimeout, value);
  }

  get gpsMaxFixingTime(): number {
    return this._gpsMaxFixingTime
  }

  set gpsMaxFixingTime(value: number) {
    this._gpsMaxFixingTime = value;

    this.setValue(StorageKeys.GpsMaxFixingTime, value);
  }

  get places(): Place[] {
    return this._places
  }

  set places(value: Place[]) {
    this._places = value;

    this.setValue(StorageKeys.Places, value);
  }

  get productRequestedAnonymousCount(): number {
    return this._productRequestedAnonymousCount
  }

  set productRequestedAnonymousCount(value: number) {
    this._productRequestedAnonymousCount = value;

    this.setValue(StorageKeys.ProductRequestedAnonymousCount, value);
  }

  get address(): DeliveryAddress[] {
    return this._address
  }

  set address(value: DeliveryAddress[]) {
    this._address = value;

    this.setValue(StorageKeys.Address, value);
  }

  get defaultAddressId(): number {
    return this._defaultAddressId
  }

  set defaultAddressId(value: number) {
    this._defaultAddressId = value;

    this.setValue(StorageKeys.DefaultAddressId, value);
  }

  get recipient(): Recipient {
    return this._recipient
  }

  set recipient(value: Recipient) {
    this._recipient = value;

    this.setValue(StorageKeys.Recipient, value);
  }

  get contact(): Contact {
    return this._contact
  }

  set contact(value: Contact) {
    this._contact = value;

    this.setValue(StorageKeys.Contact, value);
  }

  get wasMenuTutorialShown(): boolean {
    return this._wasMenuTutorialShown
  }

  set wasMenuTutorialShown(value: boolean) {
    this._wasMenuTutorialShown = value;

    this.setValue(StorageKeys.WasMenuTutorialShown, value);
  }

  get wasCartTutorialShown(): boolean {
    return this._wasCartTutorialShown
  }

  set wasCartTutorialShown(value: boolean) {
    this._wasCartTutorialShown = value;

    this.setValue(StorageKeys.WasCartTutorialShown, value);
  }

  get wasScannerTutorialShown(): boolean {
    return this._wasScannerTutorialShown
  }

  set wasScannerTutorialShown(value: boolean) {
    this._wasScannerTutorialShown = value;

    this.setValue(StorageKeys.WasScannerTutorialShown, value);
  }

  get lastLocalizationCode(): string {
    return this._lastLocalizationCode
  }

  set lastLocalizationCode(value: string) {
    this._lastLocalizationCode = value;

    this.setValue(StorageKeys.LastLocalizationCode, value);
  }

  get wasLanguagePickerShown(): boolean {
    return this._wasLanguagePickerShown
  }

  set wasLanguagePickerShown(value: boolean) {
    this._wasLanguagePickerShown = value;

    this.setValue(StorageKeys.WasLanguagePickerShown, value);
  }

  private getValue(key: string) {
    let promise = new Promise<any>((resolve, reject) => {
      this.storage.get(key + "_" + environment.name)
        .then(value => {
          resolve(JSON.parse(value));
        })
        .catch(error => {
          reject(error);
        });
    });

    return promise
      ;//.catch(error => this.log.error(error));
  }

  private setValue(key: string, value: any) {
    this.storage.set(key + "_" + environment.name, JSON.stringify(value))
  }

  removeValue(key) {
    return Promise.resolve(this.storage.remove(key + "_" + environment.name));
  }

  async clear() {
    await this.removeValue(StorageKeys.DeviceIdKey);
    await this.removeValue(StorageKeys.BrowserGuid);
    await this.removeValue(StorageKeys.IsGpsRequired);

    await this.removeValue(StorageKeys.Table);
    await this.removeValue(StorageKeys.LastTableAccess);
    await this.removeValue(StorageKeys.IdleTimeout);

    await this.removeValue(StorageKeys.User);
    await this.removeValue(StorageKeys.AllowGuest);
    await this.removeValue(StorageKeys.GuestExpireTime);
    await this.removeValue(StorageKeys.GuestName);
    await this.removeValue(StorageKeys.GuestStartDate);

    await this.removeValue(StorageKeys.CurrentAppVersion);
    await this.removeValue(StorageKeys.AccessTokenKey);
    await this.removeValue(StorageKeys.RefreshTokenKey);

    await this.removeValue(StorageKeys.LogLevel);
    await this.removeValue(StorageKeys.Logs);
    await this.removeValue(StorageKeys.FlushInterval);

    await this.removeValue(StorageKeys.GpsMinimunAcurracy);
    await this.removeValue(StorageKeys.GpsTimeout);
    await this.removeValue(StorageKeys.GpsMaxFixingTime);

    await this.init();
  }
}
export class WelcomeRegister {
  PlaceId: number;
  Date: number;
}