import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined } from 'util';
import { Errors, isNullorEmpty } from '../../common';
import { ExpressDetail, Gender, LoginRequest, ReserveDetails, SingInRequest, UpdateRequest, UserInformation, Wallet } from '../api/entities';
import { ProxyService } from '../api/proxy.service';
import { BadRequest } from '../api/ResponseCodes';
import { DeviceService } from '../core/device.service';
import { LogService } from '../diagnostic/log.service';
import { PermanentStorageService } from '../storage/permanentstorage.service';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {
  private _reserves: ReserveDetails[] = [];
  private _expresses: ExpressDetail[] = [];
  private _wallets: Wallet[] = [];

  private _refreshPromise: Promise<void>;

  constructor(
    private storage: PermanentStorageService,
    private proxy: ProxyService,
    private log: LogService,
    private device: DeviceService) { }

  setSettings(allowGuest: boolean, guestExpireTime: number, maximumProductRequestAnonymous: number) {
    this.storage.allowGuest = allowGuest;
    this.storage.guestExpireTime = guestExpireTime;
    this.storage.maximumProductRequestAnonymous = maximumProductRequestAnonymous;
  }

  get isAuthenticated(): boolean {
    return !isNullorEmpty(this.accessToken);
  }

  get hasAuthCookie() {
    return document.cookie.includes("Bystro-HasAuth-API-" + environment.name.toLocaleUpperCase() + "=true");
  }

  get isGuest(): boolean {
    return !this.isAuthenticated && !isNullorEmpty(this.storage.guestStartDate);
  }

  get isAnonymous(): boolean {
    return !this.isAuthenticated && !this.isGuest;
  }

  get allowGuest() {
    return this.storage.allowGuest && !this.hasGuestTrialExpired;
  }

  get maximumProductRequestAnonymous() {
    return this.storage.maximumProductRequestAnonymous;
  }

  get hasGuestTrialExpired(): boolean {
    //console.log(isNullorEmpty(this.storage.guestStartDate));
    if (isNullorEmpty(this.storage.guestStartDate)) {
      return false;
    }
    console.log((Date.now() - this.storage.guestStartDate) / 1000);
    return (Date.now() - this.storage.guestStartDate) > (this.storage.guestExpireTime * 1000)
  }

  get accessToken(): string {
    return this.storage.accessToken;
  }

  get username(): string {

    if (this.isAuthenticated) {
      let userInfo: UserInformation;

      userInfo = this.storage.user;

      if (isNullorEmpty(userInfo)) {
        this.log.warning("User is Authenticated but UserInfo is empty");

        return "";
      }

      return userInfo.UserName;
    }

    return "";
  }

  get fullName(): string {

    if (this.isAuthenticated) {
      let userInfo: UserInformation;

      userInfo = this.storage.user;

      if (isNullorEmpty(userInfo)) {
        return "";
      }

      return userInfo.FirstName + " " + userInfo.LastName;
    }

    if (this.isGuest) {
      return this.storage.guestName;
    }

    if (this.isAnonymous) {
      return "Anónimo";
    }
  }

  get email(): string {

    if (this.isAuthenticated) {
      let userInfo: UserInformation;

      userInfo = this.storage.user;

      if (isNullorEmpty(userInfo)) {
        return "";
      }

      return userInfo.Email;
    }

    return "";
  }

  get user() {
    if (this.isAuthenticated) {
      return this.storage.user;
    }
    else {
      return null;
    }
  }

  get reserves() : ReserveDetails[] {
    return this._reserves;
  }

  get expresses() :ExpressDetail[] {
    return this._expresses;
  }

  get wallets() : Wallet[]{
    return this._wallets;
  }

  private getErrorMessages(error): string[] {
    if (error.status == BadRequest) {
      if (!isNullorEmpty(error.error.error_description)) {
        return [error.error.error_description];
      }

      if (!isNullorEmpty(error.error.ModelState)) {
        return error.error.ModelState[""];
      }
    }

    return ["Ocurrió un problema inesperado. Por favor inténtalo de nuevo."];
  }

  private async sentLoginRequest(data: LoginRequest, extractErrors: boolean = true): Promise<void> {
    try {
      let tokens = await this.proxy.Login(data).toPromise();

      this.storage.accessToken = tokens.access_token;
      this.storage.refreshToken = tokens.refresh_token;

      let userInformation = await this.proxy.UserInformation(this.device.id);

      this.storage.user = userInformation;
    }
    catch (error) {
      if (extractErrors) {
        return Promise.reject(new Errors(this.getErrorMessages(error)));
      }
      else {
        return Promise.reject(error);
      }
    }
  }

  async signIn(username: string, name: string, lastName: string, email: string, password: string, gender: Gender, birthday: Date) {
    let data = new SingInRequest();

    data.UserName = username;
    data.Name = name;
    data.FirstLastName = lastName;
    data.SecondLastName = "";
    data.Email = email;
    data.Password = password;
    data.Gender = gender;
    data.Birthday = birthday;

    try {
      await this.proxy.SingIn(data);

      await this.login(username, password);
    }
    catch (error) {
      return Promise.reject(new Errors(this.getErrorMessages(error)));
    }

  }

  async login(username: string, password: string): Promise<void> {
    let data: LoginRequest = new LoginRequest();

    data.grant_type = LoginRequest.password_grant;
    data.username = username;
    data.password = password;

    return this.sentLoginRequest(data);
  }

  loginAsGuest(name: string) {
    if (isNullorEmpty(this.storage.guestStartDate)) {
      this.storage.guestStartDate = Date.now();
    }

    this.storage.guestName = name;

    this.proxy.RegisterGuestName(this.device.id, name);
  }

  async loginWithFacebook(facebookToken: string) {

    let data: LoginRequest = new LoginRequest();

    data.grant_type = LoginRequest.facebook_grant;
    data.accesstoken = facebookToken;

    return this.sentLoginRequest(data);
  }

  async loginWithGoogle(googleToken: string) {
    let data: LoginRequest = new LoginRequest();

    data.grant_type = LoginRequest.google_grant;
    data.accesstoken = googleToken;

    return this.sentLoginRequest(data);
  }

  async requestNewAccessToken() {

    if (!isNullOrUndefined(this._refreshPromise)) {
      return this._refreshPromise
    }

    let data: LoginRequest = new LoginRequest();

    data.grant_type = LoginRequest.refreshToken_grant;
    data.refresh_token = this.storage.refreshToken;

    this._refreshPromise = this.sentLoginRequest(data, false);

    return this._refreshPromise.then(() => { this._refreshPromise = null; });
  }

  async recoveryPassword(usernameOrEmail) {
    await this.proxy.RecoveryPassword(usernameOrEmail);
  }

  async ChangePassword(currentPassword: any, newPassword: any) {
    await this.proxy.ChangePassword(currentPassword, newPassword);
  }

  logout() {
    console.log("logout");

    this.storage.accessToken = null;
    this.storage.refreshToken = null;

    this.storage.user = null;

    this.proxy.Logout();
  }

  async updateUser(name: string, lastName: string, email: string, gender: Gender, birthday: Date) {
    let data = new UpdateRequest();

    data.Name = name;
    data.FirstLastName = lastName;
    data.SecondLastName = "";
    data.Email = email;
    data.Gender = gender;
    data.Birthday = birthday;

    try {
      await this.proxy.UpdateUser(data);

      let userInformation = await this.proxy.UserInformation(this.device.id);

      this.storage.user = userInformation;
    }
    catch (error) {
      return Promise.reject(new Errors(this.getErrorMessages(error)));
    }
  }

  async updateReserves(){
    //Se usa directamente el storage, ya que por alguna razon el localization es undefined cuando se ejecuta el windows.Onfocus
    this._reserves = await this.proxy.Reserves(this.storage.lastLocalizationCode);    
  }

  async updateExpresses(){
    //Se usa directamente el storage, ya que por alguna razon el localization es undefined cuando se ejecuta el windows.Onfocus
    this._expresses = await this.proxy.Expresses(this.storage.lastLocalizationCode);
  }

  async updateWallets(){
    this._wallets = await this.proxy.Wallets();
  }

  async Cancel(id) {
    await this.proxy.CancelReserve(id);
  }

  async CancelExpress(id) {
    await this.proxy.CancelExpress(id);
  }
}
