import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { isNullorEmpty } from 'src/app/common';
import { BadRequest, Unauthorized } from "../api/ResponseCodes";
import { LogService } from '../diagnostic/log.service';
import { AuthorizationHeaderFactory } from './authorization-header.factory';
import { PrincipalService } from './principal.service';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  constructor(
    private principal: PrincipalService,
    private headerFactory: AuthorizationHeaderFactory,
    private log: LogService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    let authReq = req.clone({ setHeaders: { Authorization: this.headerFactory.getHeader() } });

    return next.handle(authReq).pipe(
      //Error en un request
      catchError((error: HttpErrorResponse) => {
        //Si el error no es de autorización o el usuario es invitado (no esta autenticado) sigue el flujo normal
        if (error.status !== Unauthorized || (!this.principal.isAuthenticated && !this.principal.hasAuthCookie)) {
          return throwError(error);
        }

        this.log.information("Unauthorized");

        //refresca el token usando el refresh token (esto vuelve a invocar al interceptor)
        return from(this.principal.requestNewAccessToken()).pipe(
          mergeMap(() =>{

            authReq = req.clone({ setHeaders: { Authorization: this.headerFactory.getHeader() } });

            //reintenta el request original con el nuevo token
            return next.handle(authReq);
          }),
          //error al refrescar el token
          catchError((error: HttpErrorResponse) =>{  
            //se revisa el status, ya que puede ser un error del request original al reintentar
            if (error.status == BadRequest && !isNullorEmpty(error.error) && error.error.error == "invalid_grant"){    
              this.log.debug("Forced Logout");     
              this.principal.logout();
            }

            return throwError(error);
          })
        );
      })
    );
  }

}