<ion-content style="text-align: center;">
  <div data-nosnippet>
    <h1>Elije tu Idioma</h1>
    <ion-card *ngFor="let language of languages" (click)="setLanguage(language.Code)">
      <ion-card-content>
        <img [src]="language.Image">
        <h2>{{language.Name}}</h2>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>