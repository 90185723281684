import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NoLogHeader } from '../api/proxy.service';
import { LogService } from './log.service';


@Injectable()
export class LogInterceptor implements HttpInterceptor {

  constructor(public log: LogService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //HACK: se utiliza un Header para informar al Interceptor si debe o no mostrar el Loading.
    //      Se debe cambiar por un metodo para pasar metadatos a los interceptor, una vez que se incluya en Angular
    //      https://github.com/angular/angular/issues/18155
    if (req.headers.has(NoLogHeader)) {
      const headers = req.headers.delete(NoLogHeader);

      const newReq = req.clone({ headers });

      return next.handle(newReq);
    }
    else{
      let initTime = Date.now();

      this.log.debug(`Request: ${req.url}`);

      return next.handle(req).pipe(finalize(() => { this.log.debug(`Response: ${req.url} [${Date.now() - initTime}ms]`); }));
    }
  }
}