<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons *ngIf="allowBack">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" style="width: 200px; text-align: right;">
      <ion-button (click)="changeMode(modes.SignIn)" [@signinButton]="mode" style="position: absolute; right: 0;">
        {{'ACCOUNT.SIGNIN' | translate}}
      </ion-button>
      <ion-button (click)="changeMode(modes.Login)" [@loginButton]="mode" style="position: absolute; right: 0;">
        {{'ACCOUNT.LOGIN' | translate}}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="isotipo" style="margin-top: 10px;"></div>
  <div class="logotipo"></div>
  <h4 style="text-align: center;">{{message}}</h4>
  <form [formGroup]="this.loginForm" (ngSubmit)="login()" style="position: absolute; width: 100%;">
    <ion-list [@loginForm]="mode">
      <ion-item>
        <div class="error" *ngIf="loginForm.controls.username.dirty && loginForm.controls.username.invalid">
          <ion-text color="danger" *ngIf="loginForm.controls.username.errors.required">{{'GENERAL.REQUIRED' | translate}}</ion-text>
        </div>
        <ion-label position="floating">{{'ACCOUNT.USER' | translate}}</ion-label>
        <ion-input type="text" formControlName="username"></ion-input>
      </ion-item>
      <ion-item>
        <div class="error" *ngIf="loginForm.controls.password.dirty && loginForm.controls.password.invalid">
          <ion-text color="danger" *ngIf="loginForm.controls.password.errors.required">{{'GENERAL.REQUIRED' | translate}}</ion-text>
        </div>
        <ion-label position="floating">{{'ACCOUNT.PASSWORD' | translate}}</ion-label>
        <ion-input type="password" formControlName="password"></ion-input>
      </ion-item>
      <ion-label color="primary" style="text-align: right;display: block; padding-right: 20px;margin-top: 5px;"
        (click)="changeMode(modes.PasswordRecovery)">{{'ACCOUNT.FORGOTPASSWORD' | translate}}</ion-label>
      <div style="margin-top: 15px; padding-left: 20px; min-height: 20px;">
        <ion-label color="danger" *ngFor="let error of errorMessages">{{error}}</ion-label>
      </div>
    </ion-list>
    <ion-button expand="block" style="margin-top: 20px;" [@loginSubmit]="{value: mode, params: {LoginToSigInDifference: LoginToSigInDifference, LoginToOthersDifference: LoginToOthersDifference}}" type="submit">
      {{'ACCOUNT.LOGIN' | translate}}
    </ion-button>
    <div style="border-top: solid gray 2px; margin-top: 20px; padding-top: 20px;" *ngIf="mode == 'login'" @fade>
      <ion-button expand="block" color="medium" (click)="changeMode(modes.Guest)" *ngIf="principal.isAnonymous && principal.allowGuest && allowGuest">
        {{'ACCOUNT.GUESTLOGIN' | translate}}
      </ion-button>
      <!-- <ion-button expand="block" color="facebook" (click)="loginWithFacebook()">
        {{'ACCOUNT.FACEBOOKLOGIN' | translate}}
      </ion-button> -->
      <ion-button expand="block" color="google" (click)="loginWithGoogle()">
        {{'ACCOUNT.GOOGLELOGIN' | translate}}
      </ion-button>
    </div>
  </form>

  <form [formGroup]="this.signInForm" (ngSubmit)="sigin()" style="position: absolute; width: 100%;">
    <ion-list [@signinForm]="mode">
      <ion-item>
        <div class="error" *ngIf="signInForm.controls.username.dirty && signInForm.controls.username.invalid">
          <ion-text color="danger" *ngIf="signInForm.controls.username.errors.required">{{'GENERAL.REQUIRED' | translate}}</ion-text>
          <ion-text color="danger" *ngIf="signInForm.controls.username.errors.minlength">4 {{'GENERAL.MINIMUM' | translate}}</ion-text>
          <ion-text color="danger" *ngIf="signInForm.controls.username.errors.maxlength">20 {{'GENERAL.MAXIMUM' | translate}}</ion-text>
        </div>
        <ion-label position="floating">
          {{'ACCOUNT.USER' | translate}}          
        </ion-label>
        <ion-input formControlName="username"></ion-input>
      </ion-item>
      <ion-item>
        <div class="error" *ngIf="signInForm.controls.name.dirty && signInForm.controls.name.invalid">
          <ion-text color="danger" *ngIf="signInForm.controls.name.errors.required">{{'GENERAL.REQUIRED' | translate}}</ion-text>
        </div>
        <ion-label position="floating">
          {{'USER.NAME' | translate}}
        </ion-label>
        <ion-input formControlName="name"></ion-input>
      </ion-item>
      <ion-item>
        <div class="error" *ngIf="signInForm.controls.lastName.dirty && signInForm.controls.lastName.invalid">
          <ion-text color="danger" *ngIf="signInForm.controls.lastName.errors.required">{{'GENERAL.REQUIRED' | translate}}</ion-text>
        </div>
        <ion-label position="floating">
          {{'USER.LASTNAME' | translate}}
        </ion-label>
        <ion-input formControlName="lastName"></ion-input>
      </ion-item>
      <ion-item>
        <div class="error" *ngIf="signInForm.controls.email.dirty && signInForm.controls.email.invalid">
          <ion-text color="danger" *ngIf="signInForm.controls.email.errors.required">{{'GENERAL.REQUIRED' | translate}}</ion-text>
          <ion-text color="danger" *ngIf="signInForm.controls.email.errors.pattern">{{'GENERAL.INVALID' | translate}}</ion-text>
        </div>
        <ion-label position="floating" type="email">
          {{'USER.EMAIL' | translate}}
        </ion-label>
        <ion-input formControlName="email"></ion-input>
      </ion-item>
      <div formGroupName="passwordRetry">
        <ion-item>
          <div class="error"
          *ngIf="signInForm.controls.passwordRetry['controls'].password.dirty && signInForm.controls.passwordRetry['controls'].password.invalid">
          <ion-text color="danger" *ngIf="signInForm.controls.passwordRetry['controls'].password.errors.required">
            {{'GENERAL.REQUIRED' | translate}}</ion-text>
          <ion-text color="danger" *ngIf="signInForm.controls.passwordRetry['controls'].password.errors.minlength"> 6
            {{'GENERAL.MINIMUM' | translate}}</ion-text>
        </div>
          <ion-label position="floating">
            {{'USER.PASSWORD' | translate}}
          </ion-label>
          <ion-input type="password" formControlName="password"></ion-input>
        </ion-item>
        <ion-item>
          <div class="error"
          *ngIf="signInForm.controls.passwordRetry['controls'].passwordConfirmation.dirty && signInForm.controls.passwordRetry['controls'].passwordConfirmation.invalid">
          <ion-text color="danger"
            *ngIf="signInForm.controls.passwordRetry['controls'].passwordConfirmation.errors.required">{{'GENERAL.REQUIRED' | translate}}
          </ion-text>
        </div>
        <div class="error"
          *ngIf="signInForm.controls.passwordRetry.dirty && signInForm.controls.passwordRetry.invalid && signInForm.controls.passwordRetry.errors">
          <ion-text color="danger" *ngIf="signInForm.controls.passwordRetry.errors.mismatch">{{'GENERAL.NOMATCH' | translate}}
          </ion-text>
        </div>
          <ion-label position="floating">
            {{'USER.PASSWORDCONFIRM' | translate}}
          </ion-label>
          <ion-input type="password" formControlName="passwordConfirmation"></ion-input>
        </ion-item>
      </div>
      <ion-item [ngClass]="signInForm.controls.birthday.value ? '' : 'birthday'">
        <ion-label [position]="signInForm.controls.birthday.value ? 'floating' : null">{{'USER.BIRTHDATE' | translate}}</ion-label>
        <ion-datetime formControlName="birthday" displayFormat="DD MMMM YYYY"></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label position="floating">{{'USER.GENDER' | translate}}</ion-label>
        <!-- <ion-input readonly formControlName="gender" style="background-color: blue;"></ion-input> -->
        <ion-select formControlName="gender">
          <ion-select-option [value]="genders.Male">{{'USER.MALE' | translate}}</ion-select-option>
          <ion-select-option [value]="genders.Female">{{'USER.FEMALE' | translate}}</ion-select-option>
          <ion-select-option [value]="genders.Unknow">{{'USER.OTHER' | translate}}</ion-select-option>
        </ion-select>
      </ion-item>
      <div class="errors">
        <ion-label color="danger" *ngFor="let error of errorMessages">{{error}}</ion-label>
      </div>
    </ion-list>
    <ion-button type="submit" expand="block" style="margin-top: 20px;" [@signinSubmit]="{value: mode, params: {LoginToSigInDifference: LoginToSigInDifference, LoginToOthersDifference: LoginToOthersDifference}}">
      {{'GENERAL.CREATE' | translate}}
    </ion-button>
  </form>

  <form [formGroup]="this.guestForm" (ngSubmit)="loginAsGuest()" style="position: absolute; width: 100%;">
    <ion-list [@guestForm]="mode">
      <ion-item>
        <div class="error" *ngIf="guestForm.controls.name.dirty && guestForm.controls.name.invalid">
          <ion-text color="danger" *ngIf="guestForm.controls.name.errors.required">{{'GENERAL.REQUIRED' | translate}}</ion-text>
        </div>
        <ion-label position="floating">{{'GUEST.NAME' | translate}}</ion-label>
        <ion-input formControlName="name"></ion-input>
      </ion-item>
    </ion-list>
    <ion-button expand="block" style="margin-top: 20px;" [@guestSubmit]="{value: mode, params: {LoginToSigInDifference: LoginToSigInDifference, LoginToOthersDifference: LoginToOthersDifference}}" type="submit">
      {{'GENERAL.CONTINUE' | translate}}
    </ion-button>
  </form>

  <form [formGroup]="this.recoveryPasswordForm" (ngSubmit)="recoveryPassword()" style="position: absolute; width: 100%;">
    <ion-list [@recoveryPasswordForm]="mode">
      <ion-item>
        <div class="error" *ngIf="recoveryPasswordForm.controls.usernameOrEmail.dirty && recoveryPasswordForm.controls.usernameOrEmail.invalid">
          <ion-text color="danger" *ngIf="recoveryPasswordForm.controls.usernameOrEmail.errors.required">{{'GENERAL.REQUIRED' | translate}}</ion-text>
        </div>
        <ion-label position="floating">{{'RECOVERYPASSWORD.USER' | translate}}</ion-label>
        <ion-input formControlName="usernameOrEmail"></ion-input>
      </ion-item>
    </ion-list>
    <ion-button expand="block" style="margin-top: 20px;" [@recoveryPasswordSubmit]="{value: mode, params: {LoginToSigInDifference: LoginToSigInDifference, LoginToOthersDifference: LoginToOthersDifference}}" type="submit">
      {{'RECOVERYPASSWORD.RECOVERY' | translate}}
    </ion-button>
  </form>

</ion-content>