import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { isNullorEmpty } from 'src/app/common';
import { SilentHeader } from '../api/proxy.service';
import { LoadingService } from './loading.service';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(public loading: LoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq: HttpRequest<any>;

    //ya que el ngx-translate usa el HttpClient, el interceptor da error ya que aun no se an inicializado los servicios
    if (req.url.indexOf("i18n") > -1){
      return next.handle(req);
    }

    //HACK: se utiliza un Header para informar al Interceptor si debe o no mostrar el Loading.
    //      Se debe cambiar por un metodo para pasar metadatos a los interceptor, una vez que se incluya en Angular
    //      https://github.com/angular/angular/issues/18155
    if (req.headers.has(SilentHeader)) {
      let waitTime = req.headers.get(SilentHeader);

      const headers = req.headers.delete(SilentHeader);
      const newReq = req.clone({ headers });

      //si el header trae un valor numerico, se espera ese numero de milisegundos antes de mostrar el loadding;
      if (!isNullorEmpty(waitTime) && Number.parseInt(waitTime) > 0) {
        let wasLoadingShown: boolean = false;

        console.log("silent wating");
        let showLoading = setTimeout(() => {
          console.log("silent show");
          this.loading.show();
          wasLoadingShown = true;
        }, Number.parseInt(waitTime));

        return next.handle(newReq).pipe(finalize(async () => {
          if (wasLoadingShown) {
            console.log("silent hide");
            await this.loading.hide();
          }
          else {
            console.log("silent claer");
            clearTimeout(showLoading);
          }
        }));
      }

      return next.handle(newReq);
    }
    else {
      this.loading.show();

      return next.handle(req).pipe(finalize(async () => { await this.loading.hide(); }));
    }
  }
}