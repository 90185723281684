export const environment = {
  name: "Development",
  production: false,
  api: {
    server: "https://api.dev.bystro.cr",
    middleware: "https://us-central1-bystro-app-dev.cloudfunctions.net",
    user: "PWAUser",
    password: "MasFacilYRapidoConPaPedir",
  },
  table: {
    CodePrefix: "https://app.dev.bystro.cr/code/",
  },
  google:{
    mapsApiKey: "AIzaSyAjUlM0gnvxjKoPJtybbYXXvwub3OGNxJE",
    authClientId: "278332281717-big9q3qh79pjcd55krpltrcdhgn4qcu7.apps.googleusercontent.com"
  },
  facebook:{
    authClientId: "1063066110444612"
  }  
};
