import { Injectable, Injector } from '@angular/core';
import { DeviceService } from '../core/device.service';
import { LogService } from '../diagnostic/log.service';
import { LocalizationService } from '../i18n/localization.service';

@Injectable({
  providedIn: 'root'
})
export class DependencyProviderService {

  public static injector: Injector;
  public static log: LogService;
  public static device: DeviceService;
  public static localization: LocalizationService;

  constructor(injector: Injector, 
              log: LogService,
              device: DeviceService,
              localization: LocalizationService) {
    // Make the injector to be available in the entire module
    DependencyProviderService.injector = injector;
    DependencyProviderService.log = log;
    DependencyProviderService.device = device;
    DependencyProviderService.localization = localization;
  }
}
