import { ItemDetail, Product, Size } from '../api/entities';

export abstract class RequestService {
    abstract amount: number;

    abstract add(size: Size, product: Product, amount: number, comment: string, useCoins: boolean);
    abstract edit(ids: number[], size: Size, product: Product, amount: number, comment: string, useCoins: boolean);
    abstract remove(ids: number[]);
    abstract update();

    group(items: ItemDetail[]): GroupedItemDetail[] {
        var grouped: GroupedItemDetail[] = [];

        var result = items.reduce((previous, current) => {

            let key = current.ItemId.toString() + "*" + current.Options.reduce((previous, current) => {
                return previous + "-" + current.Id.toString();
            }, "") + "*" + current.Status.toString() + "*" + current.Comment + "*" + current.UseCoins;

            if (!grouped[key]) {
                grouped[key] = Object.assign({}, current); // create a copy of o
                grouped[key].Ids = [];
                previous.push(grouped[key]);
            }

            grouped[key].Ids.push(current.Id);

            return previous;
        }, []);

        return result;
    }
}

export class GroupedItemDetail extends ItemDetail {
    Ids: number[];
}