import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';
import { BadRequest, Conflict, Forbidden, Unauthorized } from './ResponseCodes';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(retryWhen(
        errors => {
          return errors.pipe(
            mergeMap((error, i) =>{
              if (i < 1 && error.status != Unauthorized && error.status != Conflict && error.status != Forbidden && error.status != BadRequest) {
                return of(error);          
              } else {          
                // Throw the error to give up retrying:
                return throwError(error);
              }
            })
          )
      }
      ));
  }

}