import { Injectable } from '@angular/core';

enum SessionStorageKeys
{  
  DirectToken = "BystroKey_DirectToken",
  Page = "BystroKey_Page",
  ForcedReloadsCount = "BystroKey_ForcedReloadsCount",   
};

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  get DirectToken() {
    return JSON.parse(sessionStorage.getItem(SessionStorageKeys.DirectToken));
  }

  set DirectToken(value) {
    sessionStorage.setItem(SessionStorageKeys.DirectToken, JSON.stringify(value));
  }

  get Page() {
    return JSON.parse(sessionStorage.getItem(SessionStorageKeys.Page));
  }

  set Page(value) {
    sessionStorage.setItem(SessionStorageKeys.Page, JSON.stringify(value));
  }

  get ForcedReloadsCount() {
    return JSON.parse(sessionStorage.getItem(SessionStorageKeys.ForcedReloadsCount));
  }

  set ForcedReloadsCount(value) {
    sessionStorage.setItem(SessionStorageKeys.ForcedReloadsCount, JSON.stringify(value));
  }
}
