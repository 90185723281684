import { Injectable } from '@angular/core';
import { Cancellation, Identification, Item, ItemDetail, ItemRequest, ItemRequestStatus, Product, Size } from '../api/entities';
import { ProxyService } from '../api/proxy.service';
import { LogService } from '../diagnostic/log.service';
import { LocalizationService } from '../i18n/localization.service';
import { ServerEventsService } from '../notifications/server-events.service';
import { PrincipalService } from '../security/principal.service';
import { PermanentStorageService } from '../storage/permanentstorage.service';
import { DeviceService } from './device.service';
import { GroupedItemDetail, RequestService } from './request.service.interface';
import { TableService } from './table.service';

@Injectable({
  providedIn: 'root'
})
export class CartService extends RequestService {
  private _requests: ItemDetail[] = [];
  private _pendingRequests: ItemDetail[] = [];
  private _distictPendingRequests: GroupedItemDetail[] = [];
  private _recentRequests: ItemDetail[] = [];
  private _distictRecentRequests: GroupedItemDetail[] = [];
  private _amount: number = 0;

  constructor(
    private device: DeviceService,
    private table: TableService,
    private proxy: ProxyService,
    private log: LogService,
    private serverEvents: ServerEventsService,
    private principal: PrincipalService,
    private storage: PermanentStorageService,
    private localization: LocalizationService) {
    super();

    this.serverEvents.onOrderUpdated.addListener(null, () => {
      this.update();
    });

    if (table.hasAny) {
      this.update();
    }
  }

  get pendingRequests() {
    return this._pendingRequests;
  }

  get distictPendingRequests() {
    return this._distictPendingRequests
  }

  get recentRequests() {
    return this._recentRequests
  }

  get distictRecentRequests() {
    return this._distictRecentRequests
  }

  get amount() {
    return this._amount;
  }

  get productsRequestedAnonymousCount() {
    return this.storage.productRequestedAnonymousCount;
  }

  async add(size: Size, product: Product, amount: number, comment: string, useCoins: boolean) {
    let promise = new Promise(async (resolve, reject) => {
      let request = new ItemRequest();

      request.Identification = new Identification();
      request.Identification.TableId = this.table.id
      request.Identification.DeviceId = this.device.id;

      let item: Item = new Item();

      item.ItemId = size.ItemId;
      item.Amount = amount;
      item.Options = [];

      size.OptionGroups.forEach((group) => {
        group.Options
          .filter(option => { return option.Selected })
          .forEach((option) => {
            item.Options.push(option.Id);
          });
      });

      request.Items = [item];

      request.Comment = comment;
      request.useCoins = useCoins;

      this._amount += item.Amount;

      try {
        let items: ItemDetail[] = await this.proxy.ItemRequest(request);

        if (!this.principal.isAuthenticated) {
          this.storage.productRequestedAnonymousCount += 1;
        }

        this.updateRequests(items);

        resolve();
      }
      catch (error) {
        this.update();
        reject(error);
      }
    });

    return promise;
  }

  async edit(ids: number[], size: Size, product: Product, amount: number, comment: string, useCoins: boolean) {
    console.log(amount);
    console.log(ids);
    if (amount > ids.length) {
      return this.add(size, product, amount - ids.length, comment, useCoins);
    }
    else if (amount < ids.length) {
      return this.remove(ids.sort().slice(-(ids.length - amount)));
    }
  }

  async remove(ids: number[]) {
    let requests = this._requests.filter(r => ids.includes(r.Id));

    if (ids.length != requests.length) {
      this.log.warning(`No todos los requests Id fueron encontrados: ${JSON.stringify(ids)} | ${JSON.stringify(requests)}`);
      return;
    }

    requests.forEach(request => {
      request.Status = ItemRequestStatus.Cancelled;
    });

    this._amount -= ids.length;

    console.log(requests);
    console.log(this._requests);

    this.updateRequests(this._requests);

    let cancellation = new Cancellation();

    cancellation.Identification = new Identification();
    cancellation.Identification.DeviceId = this.device.id;
    cancellation.Identification.TableId = this.table.id;

    cancellation.Ids = ids;

    let items = await this.proxy.CancelItems(cancellation);

    this.updateRequests(items);

  }

  async update() {
    let identification = new Identification();
    identification.TableId = this.table.id;
    identification.DeviceId = this.device.id;

    let requests = await this.proxy.LastItems(identification, this.localization.Code);

    this.updateRequests(requests);
  }

  private updateRequests(requests: ItemDetail[]) {
    this._requests = requests;

    this._pendingRequests = this._requests.filter(p => p.Status == ItemRequestStatus.Pending);
    this._recentRequests = this._requests.filter(p => p.Status != ItemRequestStatus.Pending);
    this._distictPendingRequests = this.group(this._pendingRequests);
    this._distictRecentRequests = this.group(this._recentRequests);

    this._amount = this._pendingRequests.length;
  }

  async pendingRates() {
    return await this.proxy.PendingRates(this.localization.Code);
  }

  async rate(requestId: number, title: string, starts: any, comment: any) {
    await this.proxy.RateItem(requestId, title, starts, comment);
  }
}