import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';
import { EventSourcePolyfill } from "ng-event-source";
import { isNullorEmpty } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { LogService } from '../diagnostic/log.service';
import { AuthorizationHeaderFactory } from '../security/authorization-header.factory';


@Injectable({
  providedIn: 'root'
})
export class ServerEventsService {

  private _onOrderUpdated: EventEmitter = new EventEmitter();
  private _onAssitanceUpdated: EventEmitter = new EventEmitter();
  private _onNotification: EventEmitter = new EventEmitter();

  private events: EventSourcePolyfill;

  constructor(
    private headerFactory: AuthorizationHeaderFactory,
    private log: LogService
  ) { }

  get onOrderUpdated()  {
    return this._onOrderUpdated;
  }

  get onAssitanceUpdated()  {
    return this._onAssitanceUpdated;
  }

  get onNotification()  {
    return this._onNotification;
  }

  listen(tableId: number) {
    this.stop();

    this.events = new EventSourcePolyfill(`${environment.api.server}/table/notificationsv3?TableId=${tableId}`, { headers: { Authorization: this.headerFactory.getHeader() }, errorOnTimeout: false });

    this.events.addEventListener("order-updated", (data) => {
      this.log.debug("order-updated");      
      this._onOrderUpdated.emit(null);
    });

    this.events.addEventListener("assistance-updated", (data) => {
      this.log.debug("assistance-updated");
      this._onAssitanceUpdated.emit(null);
    });

    this.events.addEventListener("new-notification", (data) => {
      this.log.debug("new-notification");
      this._onNotification.emit(null);
    });

    this.events.addEventListener("heartbeat", (data) => {
      //this.log.debug("heartbeat");
    });

    this.log.debug(`listening Table: ${tableId}`);
  }

  stop() {
    if (!isNullorEmpty(this.events)) {
      if (this.events.readyState != this.events.CLOSED) {
        this.events.close();
        this.log.debug("Stop listening the server");
      }
    }
  }
}
