import { Injectable } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { EventEmitter } from 'events';
import { isNullorEmpty } from 'src/app/common';
import { Category, Identification, Product, RequestType } from '../api/entities';
import { ProxyService } from '../api/proxy.service';
import { NotFound } from '../api/ResponseCodes';
import { Traceable } from '../decorators/traceable';
import { LogService } from '../diagnostic/log.service';
import { LocalizationService } from '../i18n/localization.service';
import { GpsService } from '../location/gps.service';
import { ServerEventsService } from '../notifications/server-events.service';
import { PermanentStorageService, WelcomeRegister } from '../storage/permanentstorage.service';
import { DeviceService } from './device.service';
import { MenuService } from './menu.service.interface';


@Injectable({
  providedIn: 'root'
})
export class TableService implements MenuService {
  private _onLeaved: EventEmitter = new EventEmitter();
  private _isWaitingCheck: boolean = false;
  private _isWaitingWaiter: boolean = false;
  private _lastMenuDate: number;

  constructor(private gps: GpsService,
    private proxy: ProxyService,
    private storage: PermanentStorageService,
    private log: LogService,
    private idle: Idle,
    private device: DeviceService,
    private serverEvents: ServerEventsService,
    private localization: LocalizationService) {

    this.serverEvents.onAssitanceUpdated.addListener(null, async () => {
      this.update();
    });

    if (this.hasAny) {
      if (Date.now() - this.storage.lastTableAccess > this.storage.idleTimeout * 1000) {
        this.log.event("TableExpired");
        this.log.debug("Table Expired");
        console.log(Date.now());
        this.leave();
      }
      else {
        this.log.event("UsingCachedTable");
        this.storage.lastTableAccess = Date.now();
        this.update();
        this.idle.watch();
        this.serverEvents.listen(this.id);
      }
    }
  }

  @Traceable()
  setSettings(idleTimeout: number) {
    this.storage.idleTimeout = idleTimeout;

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setIdle(this.storage.idleTimeout);
    this.idle.setTimeout(1);

    this.idle.onTimeout.subscribe(this.onIdleHandler.bind(this));
  }

  get onLeaved() {
    return this._onLeaved;
  }

  private onIdleHandler() {
    this.log.event("TableIdle");
    this.log.debug('Idle');
    this.leave();
  }

  get hasAny() {
    return !isNullorEmpty(this.storage.table);
  }

  get id() {
    if (this.hasAny) {
      return this.storage.table.Id;
    }
    else {
      return null;
    }
  }

  get code() {
    if (this.hasAny) {
      return this.storage.table.Code;
    }
    else {
      return null;
    }
  }

  get name() {
    if (this.hasAny) {
      return this.storage.table.Name;
    }
    else {
      return "";
    }
  }

  get sections() {
    let categories: Category[] = [];

    if (this.hasAny) {
      this.storage.table.Place.Menu.Sections.forEach(section => {
        categories = categories.concat(section.Categories);
      });
    }

    return categories;
  }

  get fixed() {
    let products: Product[] = [];

    if (this.hasAny) {
      this.storage.table.Place.Fixed.Sections.forEach(section => {
        section.Categories.forEach(category => {
          products = products.concat(category.Products);
        });
      });
    }

    return products;
  }

  get PlaceName() {
    if (this.hasAny) {
      return this.storage.table.Place.Name;
    }
    else {
      return "";
    }
  }

  get PlaceLogo() {
    if (this.hasAny) {
      return this.storage.table.Place.Logo;
    }
    else {
      return "";
    }
  }

  get showShadow() {
    if (this.hasAny) {
      return this.storage.table.Place.ShowMenuShadow;
    }
    else {
      return null;
    }
  }

  get Network() {
    if (this.hasAny) {
      return this.storage.table.Place.Network;
    }
    else {
      return null;
    }
  }

  get souldShowWelcome() {
    if (this.hasAny) {
      let lastWelcome = this.storage.lastWelcome;
      let show: boolean = undefined;
      let halfDay = 12 * 60 * 60 * 1000;

      lastWelcome.forEach((item) => {
        if (item.PlaceId == this.storage.table.Place.Id) {
          if ((Date.now() - item.Date) > halfDay) {
            //si ha pasado más de medio día lo muestra.
            show = true;
            item.Date = Date.now();
          }
          else {
            show = false;
          }
        }
      });

      //Si no lo encontró, agrega el registro y lo muestra
      if (show === undefined) {
        show = true;

        let welcome = new WelcomeRegister();

        welcome.PlaceId = this.storage.table.Place.Id;
        welcome.Date = Date.now();

        lastWelcome.push(welcome);
      }

      this.storage.lastWelcome = lastWelcome;

      return show;
    }
    else {
      return false;
    }
  }

  get lastMenuDate() {
    return this._lastMenuDate;
  }

  get readonly() : boolean{
    return false;
  }

  async update(silent: boolean = true) {
    let pendingRequest = await this.proxy.PendingAssistance(this.id, silent);

    if (!isNullorEmpty(pendingRequest)) {
      this._isWaitingCheck = pendingRequest == RequestType.Check;
      this._isWaitingWaiter = pendingRequest == RequestType.Waiter;
    }
    else {
      this._isWaitingWaiter = false;
      this._isWaitingCheck = false;
    }
  }

  async validateLocation(code: string) {
    let position = null;

    //busca la ubicación del teléfono
    try {
      position = await this.gps.getPosition();
    }
    catch (error) {
      this.log.warning(error);
      //si no la encuentra y el teléfono esta restringido, entonces genera un error
      if (this.device.isGpsRequired) {
        if (error.code == this.gps.PERMISSION_DENIED) {
          this.log.warning("GPS permission was denied");
          throw new TableError(TableError.GpsDenied);
        } else if (error.code == this.gps.POSITION_UNAVAILABLE) {
          this.log.warning("GPS position was not found");
          throw new TableError(TableError.GpsError);
        } else if (error.code == this.gps.TIMEOUT) {
          this.log.warning("GPS timeout");
          throw new TableError(TableError.GpsError);
        }
      }
    }

    let isValid;

    try {
      //si es nulo, significa que dio error, pero el gps no es requerido, asi que lo deja pasar
      if (isNullorEmpty(position)) {
        return;
      }

      isValid = await this.proxy.ValidateLocation(code, position.coords.accuracy, position.coords.latitude, position.coords.longitude);
    }
    catch (error) {
      this.log.error(error);
      if (this.device.isGpsRequired) {
        throw new TableError(TableError.Unknow);
      }
      else {
        return;
      }
    }

    if (!isValid) {
      this.log.warning(`Conflict getting the code: ${code}, Acc: ${position.coords.accuracy} Lat: ${position.coords.latitude} Long: ${position.coords.longitude}`);
      throw new TableError(TableError.Conflict);
    }
  }

  async join(code: string) {
    try {
      this.log.event("CodeScanned", Date.now(), code);

      //se envian los valores en 0, para mantener compatibilidad.
      let table = await this.proxy.Identify(code, 0, 0, 0, this.localization.Code);

      this.log.event("CodeResolved");

      this._lastMenuDate = Date.now();
      table.Code = code;

      this.storage.table = table;

      this.storage.lastTableAccess = Date.now();

      //Asegura que el logo se cargue antes que las imágenes del menú
      var img = new Image();
      img.src = table.Place.Logo;

      //Prioriza las Promociones antes que las imágenes del menú
      table.Place.Fixed.Sections.forEach(section => {
        section.Categories.forEach(category => {
          category.Products.forEach(product => {
            var img = new Image();
            img.src = product.Image;
          })
        });
      });

      this.update();
      this.serverEvents.listen(this.id);
      this.idle.watch();
    }
    catch (error) {
      if (error.status == NotFound) {
        this.log.warning(`Code not Found: ${code}`);
        throw new TableError(TableError.NotFound);
      } else {
        this.log.error(error);
        throw new TableError(TableError.Unknow);
      }
    }
  }

  async refresh() {
    if (this.hasAny) {
      await this.join(this.storage.table.Code);
    }
  }

  leave() {
    this.log.event("TableLeaved");
    this.storage.table = null;
    this._onLeaved.emit(null);
    this.serverEvents.stop();
    this.idle.stop();
  }

  get isWaiting() {
    return this._isWaitingCheck || this.isWaitingWaiter;
  }

  get isWaitingCheck() {
    return this._isWaitingCheck;
  }

  get isWaitingWaiter() {
    return this._isWaitingWaiter;
  }

  async requestWaiter() {
    let identification: Identification = new Identification();

    identification.DeviceId = this.device.id;
    identification.TableId = this.id;

    this._isWaitingWaiter = true;
    return this.proxy.WaiterRequest(identification);
  }

  async requestCheck() {
    let identification: Identification = new Identification();

    identification.DeviceId = this.device.id;
    identification.TableId = this.id;

    this._isWaitingCheck = true;
    return this.proxy.CheckRequest(identification);
  }

  async cancelRequest() {
    let identification: Identification = new Identification();

    identification.DeviceId = this.device.id;
    identification.TableId = this.id;

    this._isWaitingCheck = false;
    this._isWaitingWaiter = false;
    return this.proxy.CancelRequest(identification);
  }

  getProduct(itemId: number): Product {
    //Se busca en Fixed primero, ya que deberian ser menos
    for (let s = 0; s < this.storage.table.Place.Fixed.Sections.length; s++) {
      let section = this.storage.table.Place.Fixed.Sections[s];
      for (let c = 0; c < section.Categories.length; c++) {
        let category = section.Categories[c];
        for (let p = 0; p < category.Products.length; p++) {
          let product = category.Products[p];
          for (let z = 0; z < product.Sizes.length; z++) {
            let size = product.Sizes[z];
            if (size.ItemId == itemId) {
              return product;
            }
          }
        }
      }
    }
    
    //Luego se busca en el menu
    for (let s = 0; s < this.storage.table.Place.Menu.Sections.length; s++) {
      let section = this.storage.table.Place.Menu.Sections[s];
      for (let c = 0; c < section.Categories.length; c++) {
        let category = section.Categories[c];
        for (let p = 0; p < category.Products.length; p++) {
          let product = category.Products[p];
          for (let z = 0; z < product.Sizes.length; z++) {
            let size = product.Sizes[z];
            if (size.ItemId == itemId) {
              return product;
            }
          }
        }
      }
    }

    return null;
  }
}

export class TableError extends Error {
  static readonly GpsDenied: number = 1;
  static readonly GpsError: number = 2;
  static readonly NotFound: number = 3;
  static readonly Conflict: number = 4;
  static readonly Unknow: number = 5;

  private _code: number;

  get Code() {
    return this._code;
  }

  constructor(code: number) {
    super();
    this._code = code;
  }
}
