import { appVersion } from 'src/app/common';

//#region Settings

export class Settings {
  constructor() {
    //Default Settings
    this.MinimunSupportedAppVersion = appVersion;
    this.ClearStoragedData = false;
    this.LeaveTable = false;

    this.Device = new DeviceSettings();
    this.Table = new TableSettings();
    this.Security = new SecuritySettings();
    this.Log = new LogSettings();
    this.Gps = new GpsSettings();
  }

  MinimunSupportedAppVersion: string;
  ClearStoragedData: boolean;
  LeaveTable: boolean;

  Locations: Localization[];
  Device: DeviceSettings;
  Table: TableSettings;
  Security: SecuritySettings;
  Log: LogSettings;
  Gps: GpsSettings;
}

export class DeviceSettings {
  constructor() {
    this.IsGpsRequired = false;
  }

  IsGpsRequired: boolean;
}

export class TableSettings {
  constructor() {
    this.IdleTimeout = 1 * 60 * 60; //1 hora
  }

  IdleTimeout: number;
}

export class SecuritySettings {
  constructor() {
    this.AllowGuest = true;
    this.GuestExpireTime = 3 * 24 * 60 * 60; // 3 dias
    this.MaximumProductRequestAnonymous = 3;
  }

  AllowGuest: boolean;
  GuestExpireTime: number;
  MaximumProductRequestAnonymous: number;
}

export class LogSettings {
  constructor() {
    this.FlushInterval = 1; //1 segundo
    this.Level = LogLevels.Warning;
  }

  FlushInterval: number;
  Level: LogLevels;
}

export class GpsSettings {
  constructor() {
    this.MinimunAccuracy = 500; //500 metros
    this.MaxFixingTime = 5; //5 segundos
    this.Timeout = 20; //20 segundos
  }

  MinimunAccuracy: number;
  MaxFixingTime: number;
  Timeout: number;
}

//#endregion

export class LoginRequest {
  static readonly password_grant: string = "password";
  static readonly accessToken_grant: string = "access_token";
  static readonly refreshToken_grant: string = "refresh_token";
  static readonly facebook_grant: string = "facebook";
  static readonly google_grant: string = "google";

  grant_type: string;
  username?: string;
  password?: string;
  accesstoken?: string;
  refresh_token?: string;
}

export class LoginResponse {
  access_token: string;
  refresh_token: string;
}

export interface Register {
  Id: number;
  Code: string;
  Settings: Settings;  
}

export class Localization{
  Code: string;
  Name: string;
  Image: string;  
  Settings: Settings;
}

export class SingInRequest {
  UserName: string;
  Name: string;
  FirstLastName: string;
  SecondLastName: string;
  Email: string;
  Password: string;
  Gender: number;
  Birthday: Date;
}

export class UpdateRequest {
  Name: string;
  FirstLastName: string;
  SecondLastName: string;
  Email: string;
  Gender: number;
  Birthday: Date;
}

export class UserInformation {
  UserName: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Gender: Gender;
  Birthday: Date;
}

export enum Gender {
  Male = 0,
  Female = 1,
  Unknow = 2
}

export class TableInfo {
  Id: number;
  Code: string;
  Name: string;
  Place: Place;
}

export class Network {
  SSID: string;
  Password: string;
}

export class Menu {
  Sections: Section[];
}

class Section {
  Name: string;
  Image: string;
  Categories: Category[];
}

export class Category {
  Name: string;
  Products: Product[];
}

export enum HighlightType{
  None,
  Fixed,
  Top,
  New,
  Promo
}

export class Product {
  Name: string;
  Image: string;
  Thumbnail: string;
  Description: string;
  Rate: number;
  RateCount: number;
  Sizes: Size[];
  Highlight: HighlightType;
  OptionGroups: OptionGroup[];
  IsSoldOut: boolean;

  src: string;
  loaded: boolean;
  thumbnailSrc: string;
  thumbnailLoaded: boolean;
  hasReward: boolean;
  multiplicator: number;
  hasCoinPrice: boolean;  
}

export class Size {
  ItemId: number;
  Name: string;
  Description: string;
  Image: string;
  Price: number;
  CoinsPrice: number;
  CoinsReward: number;
  Multiplicator: number;
  Amount: number;
  IsSoldOut: boolean;
  OptionGroups: OptionGroup[]
}

export class OptionGroup {
  Name: string;
  Image: string;
  Amount: number;
  Options: Option[];
  Mandatory: boolean;
  isFull: boolean = false;
}

export class Option {
  Id: number;
  Name: string;
  Image: string;
  Selected: boolean;
  Price: number;
  CoinsPrice: number;
  IsSoldOut: boolean;
}

export class Promotion {
  Id: number;
  Image: string;
}

export class RegisterGuestName {
  DeviceId: number;
  GuestName: string;
}

export class Identification {
  TableId: number;
  DeviceId: number;
}

export enum LogLevels {
  None = 0,
  Critical = 1,
  Error = 2,
  Warning = 3,
  Information = 4,
  Trace = 5,
  Debug = 6
}

export class MobileEvent {
  DeviceId: number;
  Event: string;
  PerformanceEvents: PerfomnaceEvent[];
}

export class MobileError {
  DeviceId: number;
  Version: string;
  Error: string;
}

export class PerfomnaceEvent{
  DeviceCode: string;
  Type: string;
  Time: number;
  Details: string;
}

export class Item {
  ItemId: number;
  Amount: number;
  Options: number[];  
}

export class ItemRequest {
  Identification: Identification;
  useCoins: boolean;
  Items: Item[];
  Comment: string;
}

export class ItemDetail {
  Id: number;
  ItemId: number;
  ProductId: number;
  ProductName: string;
  ProductImage: string;
  SizeId: number;
  SizeName: string;
  SizeImage: string;
  Price: number;
  CoinsPrice: number;
  UseCoins: boolean;
  Status: ItemRequestStatus;
  Options: Option[];
  Comment: string;
}

export class Cancellation {
  Identification: Identification;
  Ids: number[];
}

export enum ItemRequestStatus {
  Pending = 0,
  Attended = 1,
  Deleted = 2,
  Cancelled = 3,
}

export enum RequestType {
  Waiter = 0,
  Check = 1
}

export class ItemRate {
  RequestId: number;
  Stars: number;
  Comment: string;
  Title: string;
}


export class PlaceRate {
  PlaceId: number;
  Stars: number;
  Comment: string;
  Title: string;
  Date: string;
  JsDate: Date;
}

export class RateDetail {
  RequestId: number;
  ClientName: string;
  ProductImage: string;
  ProductName: string;
  Stars: number;
  Comment: string;
  Title: string;
}

export class Place {
  Id: number
  DirectToken: string
  Name: string
  Address: string
  Latitude: number
  Longitude: number
  Image: string
  Rate: number
  RateCount: number
  Logo: string
  Phones: string[]
  SinpeMovilPhone: string
  PriceScale: PriceScale
  IsPetFriendly: boolean
  HasVegetarianFood: boolean
  HasVeganFood: boolean
  HasGlutenFreeFood: boolean
  HasExpress: boolean
  ShowMenuShadow: boolean
  MinTime: number
  MaxTime: number
  IsOpen: boolean
  IsExpressOpen: boolean
  Description: string
  PlaceImages: string[]
  FoodImages: string[]
  Schedule: Schedule[]
  Rates: PlaceRate[]
  Network: Network;
  Menu: Menu;
  Fixed: Menu;

  marker: google.maps.Marker;
}

export enum PriceScale {
  RellyCheap = 1,
  Cheap = 2,
  Normal = 3,
  Expensive = 4,
  ReallyExpensive = 5
}

export class Schedule {
  Day: string
  JsDay: Date
  IsOpen: boolean
  Open: string
  Close: string
  KitchenClose: string
}

export class Reserve {
  Date: Date;
  Contact: Contact;
  Persons: number;
  PlaceId: number;
}

export class ReserveDetails {
  Id: number;
  Date: string;
  JsDate: Date;
  PlaceName: string;
  PlaceLogo: string;
  Status: ReserveStatus;
}

export enum ReserveStatus {
  Pending,
  Accepted,
  Rejected,
  Cancelled
}

export class Notification {
  Id: number;
  Text: string;
  Image: string;
  RedirectUrl: string;
}

export class ExpressRequest {
  TransactionCode: string;
  PlaceId: number;
  Total: number;
  Items: ItemRequest[];
  Address: DeliveryAddress;
  Contact: Contact;
  PaymentType: PaymentType;
  Recipient: Recipient;
  Payment: number;
}

export class DeliveryAddress {
  Id: number;
  Name: string;
  Province: Location;
  Canton: Location;
  District: Location;
  Neighborhood: Location;
  Latitude: number;
  Longitude: number;
  Address: string;
}

export class Contact {
  Name: string;
  Phone: string;
}

export class DeliveryPrice {
  Price: number;
  MinTime: number;
  MaxTime: number;
  MinimumAmount: number;
}

export class Recipient {
  IdentificationType: IdentificationType;
  Identification: string;
  Name: string;
  Email: string;
}

export enum IdentificationType {
  PhysicalId,
  JuridicId,
  DIMEX,
  NITE
}

export enum PaymentType {
  None,
  Cash,
  Card,
  SinpeMovil
}

export class ExpressDetail {
  Id: number;
  TransactionCode: string;
  PlaceName: string;
  PlaceLogo: string;
  PlacePhone: string;
  Total: number;
  Status: ExpressRequestStatus
  Items: ItemDetail[];
  Address: DeliveryAddress;
  DeliveryPrice: number;
  CancelledMessage: string;
}

export enum ExpressRequestStatus {
  Pending,
  Attended,
  OnTheWay,
  Delivered,
  Cancelled
}

export abstract class Location {
  Id: number;
  Name: string;
}

export class Province extends Location {
  Cantons: Canton[];
}

class Canton extends Location {
  Districts: District[];
}

class District extends Location {
  Neighborhoods: Neighborhood[];
}

class Neighborhood extends Location {
}

export class Wallet{
  Name: string;
  Logo: string;
  LoyaltyPlanPolicy: string;
  PendingCoins: number;
  AvailableCoins: number;
  UsedCoins: number;
  ExpiredCoins: number;
  ExpireDate: string;
  JsExpireDate: Date;
}