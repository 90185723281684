import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { environment } from "../environments/environment";
import { appVersion, isNullorEmpty, Platforms } from './common';
import { LoginPage } from './login/login.page';
import { Settings } from './services/api/entities';
import { ProxyService } from './services/api/proxy.service';
import { GatewayTimeout, Unknown } from './services/api/ResponseCodes';
import { CartService } from './services/core/cart.service';
import { DeviceService } from './services/core/device.service';
import { TableService } from './services/core/table.service';
import { Traceable } from './services/decorators/traceable';
import { LogService } from './services/diagnostic/log.service';
import { LocalizationService } from './services/i18n/localization.service';
import { GpsService } from './services/location/gps.service';
import { NotificationService } from './services/notifications/notification.service';
import { ServerEventsService } from './services/notifications/server-events.service';
import { PrincipalService } from './services/security/principal.service';
import { PermanentStorageService } from './services/storage/permanentstorage.service';


@Traceable()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  appClass: string = "";

  constructor(
    private platform: Platform,
    private proxy: ProxyService,
    private storage: PermanentStorageService,
    private device: DeviceService,
    private log: LogService,
    private gps: GpsService,
    private table: TableService,
    private principal: PrincipalService,
    private modal: ModalController,
    private notifications: NotificationService,
    private alertController: AlertController,
    private serverEvents: ServerEventsService,
    private cart: CartService,
    private router: Router,
    private toast: ToastController,
    private alert: AlertController,
    private localization: LocalizationService,
    private swUpdate : SwUpdate
  ) {

    if (this.platform.is("desktop") || this.platform.is("tablet")) {
      this.appClass = "notMobile";
    }

    this.initializeApp();
  }


  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is(Platforms.IOS)) {
        document.addEventListener('touchmove', function (event: any) {
          event = event.originalEvent || event;
          if (event.scale > 1) {
            event.preventDefault();
          }
        }, false);

        document.addEventListener('gesturestart', function (e) {
          e.preventDefault();
        });
      }
    });
  }

  async ngOnInit() {  
    this.log.information(`Environment: ${environment.name}`);

    this.log.information(`Version: ${appVersion}`);

    this.device.logInformation(); 

    this.log.debug(JSON.stringify(performance.timing))
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.log.trace(`Navigation: ${event.url}`);
      }
    });

    let settings: Settings;


    setInterval(() => async function(){
      await this.device.register();
    }, 1000);

    try {
      settings = await this.device.register();

      if (settings.ClearStoragedData) {
        await this.storage.clear();

        //vuelve a registrar el teléfono
        settings = await this.device.register();
      }
    }
    catch (error) {
      this.log.warning(`LOOKS OFFLINE: ${JSON.stringify(error)} (${error.code} : ${error.name} : ${error.message} : ${error.stack})`);

      try {
        await this.proxy.isAlive();

        settings = await this.device.register();

        if (settings.ClearStoragedData) {
          await this.storage.clear();

          //vuelve a registrar el teléfono
          settings = await this.device.register();
        }
      }
      catch (error) {
        this.log.information("OFFLINE");

        this.device.makeOffLine();

        settings = new Settings(); //Carga los valores por defecto

        this.MonitorServer();
      }
    }
    
    this.log.event("navigationStart", performance.timing.navigationStart);
    this.log.event("fetchStart", performance.timing.fetchStart);
    this.log.event("domainLookupStart", performance.timing.domainLookupStart);
    this.log.event("domainLookupEnd", performance.timing.domainLookupEnd);
    this.log.event("connectStart", performance.timing.connectStart);
    this.log.event("secureConnectionStart", performance.timing.secureConnectionStart);
    this.log.event("connectEnd", performance.timing.connectEnd);

    this.log.event("requestStart", performance.timing.requestStart);
    this.log.event("responseStart", performance.timing.responseStart);
    this.log.event("responseEnd", performance.timing.responseEnd);

    this.log.event("domLoading", performance.timing.domLoading);
    this.log.event("domInteractive", performance.timing.domInteractive);
    this.log.event("domContentLoadedEventStart", performance.timing.domContentLoadedEventStart);
    this.log.event("domContentLoadedEventEnd", performance.timing.domContentLoadedEventEnd);
    this.log.event("domComplete", performance.timing.domComplete);

    this.log.event("loadEventStart", performance.timing.loadEventStart);
    this.log.event("loadEventEnd", performance.timing.loadEventEnd);

    this.log.event("Load", window['BTLoadEvent']);
    this.log.event("Init", window['BTInitEvent']);


    this.localization.setLanguages(settings.Locations);

    this.localization.useLast();

    this.device.setSettings(settings.Device.IsGpsRequired);

    this.table.setSettings(settings.Table.IdleTimeout);

    this.principal.setSettings(settings.Security.AllowGuest, settings.Security.GuestExpireTime, settings.Security.MaximumProductRequestAnonymous);

    this.log.setSettings(settings.Log.FlushInterval, settings.Log.Level);

    this.gps.setSettings(settings.Gps.MinimunAccuracy, settings.Gps.MaxFixingTime, settings.Gps.Timeout);

    this.CheckNewVersion();

    //Se llama para mostrar los permisos e iniciar el gps, así dure menos en el escáner
    // this.gps.getPosition()
    //   .catch(error => {
    //     this.log.warning(error);
    //   });

    // if (settings.LeaveTable) {
    //   await this.table.leave();
    // }

    //Se carga el modal fuera del If, para que precargue el componente
    //otros componentes se precargan dentro del <ion-app>
    this.modal.create({
      component: LoginPage,
      componentProps: {
        allowBack: false,
        allowGuest: false
      },
      backdropDismiss: false
    }).then(modal =>{
      if (this.principal.isGuest && !this.principal.allowGuest) {
        modal.present();
      }
    });

    this.showNotifications();

    //se elimina ya que por el momento no hay aplicaciones de Android o IOS
    // this.platform.resume.subscribe(() => {
    //   this.onFocus();
    // });

    this.serverEvents.onNotification.addListener(null, () => {
      this.showNotifications();
    });

    this.toast.create();
    this.alert.create();

    let lastTime = Date.now();

    setInterval(() =>{
      //el setInterval se pausa cuando se envia el browser al background en IOS/Android
      //si el tiempo es mayor al rango del intervalo se presume que la aplicacion estuvo en background
      let difference = Date.now() - lastTime;
      
      if (difference > 30000){ //30 segundos
        this.log.debug(`Background Time: ${difference}ms`)
        this.RefreshState();
      }
      lastTime = Date.now();
    }, 5000);
  }

  public RefreshState() {
    //si se minimiza y vuelve antes de que el device identify responda, entonces el Id aun es null
    if (this.device.id != null) {
      let requestTime = Date.now();
      console.log("Focus");
      let errorHandler = (error) =>{
        console.log("error of Focus");
        if (error.status == GatewayTimeout || error.status == Unknown){
          console.log(error.status);
          this.log.debug(`Enlapsed time: ${(Date.now() - requestTime).toString()}`);
          //Si han pasado mas de 10 segundos se guarda como un warning y no como error
          //esto ya que en ciertos teléfonos (IOS) si se minimiza el Browser antes de tener respuesta, 
          //cuando se abre de nuevo da error
          if (Date.now() - requestTime > 10 * 1000)  //10 segundos
          {
            this.log.warning(error);
            return;
          }
        }

        this.log.error(error);
      }

      this.showNotifications().catch(errorHandler);
      this.principal.updateReserves().catch(errorHandler);
      this.principal.updateExpresses().catch(errorHandler);

      if (this.table.hasAny) {
        this.cart.update().catch(errorHandler);

        //Se usa el time out ya q en redes muy lentas el update llega antes que el waiter/check request y muestra el que no es.
        //esto no soluciona el problema pero lo minimiza.
        setTimeout(() => {
          //se vuelve a validar ya que en el segundo y medio del timeout, puede ser que el usuario dejarla mesa (poco probable)
          //o que el Idle timeout forzara la salida (mas probable)
          if (this.table.hasAny) {
            this.table.update().catch(errorHandler);
          }
        }, 1500);
      }
    }
  }

  private async CheckNewVersion(){
    this.swUpdate.available.subscribe(async event => {
      this.log.event("NewVersionDetected", Date.now(), "Current Version:" + appVersion);
      this.log.information(`current version is ${event.current.hash}`);
      this.log.information(`available version is ${event.available.hash}`);
      this.log.flush();
      
      const alert = await this.alertController.create({
        header: 'Bystro',
        message: "Nueva versión disponible.<br />Se cargará de nuevo para obtener las últimas mejoras.",
        backdropDismiss: false,
        buttons: [
          {
            text: 'Ok',
            handler: async () => {
              this.swUpdate.activateUpdate().then(() => document.location.reload());
            }
          }
        ]
      });

      await alert.present();
    });

    this.swUpdate.activated.subscribe(event => {
      this.log.information(`old version version ${event.previous.hash}`);
      this.log.information(`new version is ${event.current.hash}`);
      this.log.flush();
    });
  }

  private MonitorServer() {
    let monitor = setTimeout(async () => {
      try {
        await this.proxy.isAlive();

        this.device.makeOnLine();

        clearTimeout(monitor);

        this.log.information("BACK ONLINE");

        await this.ngOnInit();
      }
      catch (error) {
        this.log.debug("Still Offline");

        this.MonitorServer();
      }
    }, 30000); //30 segnudos
  }

  private async showNotifications() {
    let notification = await this.notifications.checkPending();

    if (!isNullorEmpty(notification)) {
      const alert = await this.alertController.create({
        header: 'Bystro',
        message: notification.Text,
        backdropDismiss: false,
        buttons: [
          {
            text: this.localization.get('GENERAL.OK'),
            handler: async () => {
              this.notifications.masrAsViewed(notification.Id);
            }
          }
        ]
      });

      await alert.present();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  WindowBeforeUnoad($event: any) {
    this.log.event("PageLeave");
    this.log.flush();
  }
}
