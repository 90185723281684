import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { NgIdleModule } from "@ng-idle/core";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from "angularx-social-login";
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LanguagePickerPageModule } from './language-picker/language-picker.module';
import { LanguagePickerPage } from './language-picker/language-picker.page';
import { LoginPageModule } from './login/login.module';
import { LoginPage } from './login/login.page';
import { RetryInterceptor } from './services/api/retry.security.Interceptor';
import { DependencyProviderService } from './services/decorators/dependency-provider.service';
import { ErrorHandlerService } from './services/diagnostic/error-handler.service';
import { LogInterceptor } from './services/diagnostic/log.interceptor';
import { LogService } from './services/diagnostic/log.service';
import { LoadingInterceptor } from './services/loading/loading.interceptor';
import { LoadingService } from './services/loading/loading.service';
import { SecurityInterceptor } from './services/security/security.Interceptor';
import { PermanentStorageService } from './services/storage/permanentstorage.service';
 


export function initializeApp(storage: PermanentStorageService, loading: LoadingService, log: LogService) {
  return () =>  Promise.all([log.init(), storage.init(), loading.init()]);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [LoginPage, LanguagePickerPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ swipeBackEnabled: false}),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoginPageModule,
    LanguagePickerPageModule,
    SocialLoginModule,
    NgIdleModule.forRoot(),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [PermanentStorageService, LoadingService, LogService], multi: true},
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    FormBuilder,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},    
    { provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.google.authClientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebook.authClientId),
          },
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public static injector: Injector;

  constructor(dependencyProvider: DependencyProviderService) {
    // Make the dependency provider to be available in the entire module
  }
}
