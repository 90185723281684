import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Localization } from '../services/api/entities';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.page.html',
  styleUrls: ['./language-picker.page.scss'],
})
export class LanguagePickerPage implements OnInit {
@Input() languages: Localization[];

  constructor(private modalControler: ModalController) { }

  ngOnInit() {
  }

  setLanguage(code: string){
    this.modalControler.dismiss({ code: code}, undefined, "languagePickerModal");
  }
}
