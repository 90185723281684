import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { isNullorEmpty } from 'src/app/common';
import { LanguagePickerPage } from 'src/app/language-picker/language-picker.page';
import { Localization } from '../api/entities';
import { LogService } from '../diagnostic/log.service';
import { PermanentStorageService } from '../storage/permanentstorage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  _locations: Localization[];
  _currentLocation: Localization;
  _showPickerOnLoad: boolean = false;

  constructor(
    private transalate: TranslateService,
    private log: LogService,
    private storage: PermanentStorageService,
    private modalController: ModalController) { 
      //se usa directamente el translate, ya que aun no se an cargado los Localization desde el api
      this.transalate.use(this.storage.lastLocalizationCode);  
    }

  get locations(){
    return this._locations;
  }

  get Code(){
    if (!isNullorEmpty(this._currentLocation)){
      return this._currentLocation.Code;
    }
    else{
      return this.storage.lastLocalizationCode;
    }
  }

  get Name(){
    return this._currentLocation.Name;
  }

  get Image(){
    return this._currentLocation.Image;
  }

  setLanguages(locations: Localization[]){
    this._locations = locations;
    
    let languages: string[] = [];

    locations.forEach(location => {
      //se cargan las imagenes para tenerlas en cache
      var img = new Image();
      img.src = location.Image;

      languages.push(location.Code);
    });

    this.transalate.addLangs(languages);  
    
    if(this._showPickerOnLoad){
      this.showPicker();
    }
  }

  getLanguages(){
    return this._locations;
  }

  setDefault(code: string){
    this.transalate.setDefaultLang(code); 
    
    this.use(code);
  }

  use(code: string){
    this.log.information(`Localization Change: ${code}`);

    this.locations.forEach(location => {
      if (location.Code == code){
        this.log.debug(`Localization Found: ${location.Name}`);
        this._currentLocation = location;
        this.transalate.use(code);

        this.storage.lastLocalizationCode = code;
      }
    });
  }

  useLast(){
    this.use(this.storage.lastLocalizationCode);
  }

  get(key: string, params?: Object){
    return this.transalate.instant(key, params);
  }

  async showPicker(){
    let promise = new Promise(async (resolve, reject) =>{
      if (!this.storage.wasLanguagePickerShown){
        if(isNullorEmpty(this.getLanguages())){
          this._showPickerOnLoad = true;
          resolve();
          return;
        }
  
        const modal = await this.modalController.create({
          componentProps:{
            languages: this.getLanguages()
          },
          component: LanguagePickerPage,
          backdropDismiss: false,
          showBackdrop: true,
          cssClass: "languagePickerModal",
          id: "languagePickerModal"
        });
  
        await modal.present();
  
        let result = await modal.onDidDismiss();
  
        this.use(result.data.code);
  
        this.storage.wasLanguagePickerShown = true;

        resolve();
      }
    });

    return promise;
  }
}
