import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'code/:code',
    loadChildren: () => import('./code/code.module').then( m => m.CodePageModule)
  },
  {
    path: ':DirectToken',
    loadChildren: () => import('./direct-token/direct-token.module').then( m => m.DirectTokenPageModule),
    pathMatch: 'prefix'
  },
  {
    path: ':DirectToken/:Page',
    loadChildren: () => import('./direct-token/direct-token.module').then( m => m.DirectTokenPageModule),
    pathMatch: 'prefix'
  },
  {path: '**', redirectTo: ''}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
