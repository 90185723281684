import { Injectable } from '@angular/core';
import { ProxyService } from '../api/proxy.service';
import { DeviceService } from '../core/device.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private proxy: ProxyService,
    private device: DeviceService) { }

  checkPending(){
    return this.proxy.PendingNotifications(this.device.id);
  }

  masrAsViewed(notificationId){
    this.proxy.MarkAsViewed(this.device.id, notificationId);
  }
}
