import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { Errors, isNullorEmpty, matchValidator, modalState, Patterns, Platforms } from '../common';
import { Gender } from '../services/api/entities';
import { Traceable } from '../services/decorators/traceable';
import { LogService } from '../services/diagnostic/log.service';
import { LocalizationService } from '../services/i18n/localization.service';
import { LoadingService } from '../services/loading/loading.service';
import { PrincipalService } from '../services/security/principal.service';

export enum Modes {
  Login = "login",
  SignIn = "signin",
  Guest = "guest",
  PasswordRecovery = "passwordRecovery"
}

@Traceable()
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate(200, style({ opacity: 0 })),
      ])
    ]),
    trigger('move', [
      transition(':enter', [
        style({ transform: 'translateY(100px)' }),
        animate(200, style({ transform: '*' })),
      ]),
      transition(':leave', [
        animate(200, style({ transform: 'translateY(-100px)' })),
      ])
    ]),
    trigger('loginButton', [
      state(Modes.Login, style({
        display: 'none'
      })),
      state('*', style({
        display: '*'
      })),
      transition(`* => ${Modes.Login}`, [
        animate('0.5s',
          style({
            opacity: 0
          }))
      ]),
      transition(`${Modes.Login} => *`, [
        style({
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            opacity: 1
          }))
      ]),
    ]),
    trigger('signinButton', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.Login, style({
        display: '*'
      })),
      transition(`${Modes.Login} => *`, [
        animate('0.5s',
          style({
            opacity: 0
          }))
      ]),
      transition(`* => ${Modes.Login}`, [
        style({
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            opacity: 1
          }))
      ])
    ]),
    trigger('signinForm', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.SignIn, style({
        display: '*'
      })),
      transition(`${Modes.SignIn} => ${Modes.Login}`, [
        animate('0.5s',
          style({
            opacity: 0
          }))
      ]),
      transition(`${Modes.Login} => ${Modes.SignIn}`, [
        style({
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            opacity: 1
          }))
      ]),
    ]),
    trigger('loginForm', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.Login, style({
        display: '*'
      })),
      transition(`${Modes.Login} => *`, [
        animate('0.5s',
          style({
            opacity: 0
          }))
      ]),
      transition(`* => ${Modes.Login}`, [
        style({
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            opacity: 1
          }))
      ])
    ]),
    trigger('guestForm', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.Guest, style({
        display: '*'
      })),
      transition(`${Modes.Guest} => ${Modes.Login}`, [
        animate('0.5s',
          style({
            opacity: 0
          }))
      ]),
      transition(`${Modes.Login} => ${Modes.Guest}`, [
        style({
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            opacity: 1
          }))
      ])
    ]),
    trigger('recoveryPasswordForm', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.PasswordRecovery, style({
        display: '*'
      })),
      transition(`${Modes.PasswordRecovery} => ${Modes.Login}`, [
        animate('0.5s',
          style({
            opacity: 0
          }))
      ]),
      transition(`${Modes.Login} => ${Modes.PasswordRecovery}`, [
        style({
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            opacity: 1
          }))
      ])
    ]),
    trigger('signinSubmit', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.SignIn, style({
        display: '*'
      })),
      transition(`${Modes.SignIn} => ${Modes.Login}`, [
        animate('0.5s',
          style({
            transform: "translateY(-{{LoginToSigInDifference}}px)",
            opacity: 0
          }))
      ], { params: { LoginToSigInDifference: 380 } }),
      transition(`${Modes.Login} => ${Modes.SignIn}`, [
        style({
          transform: "translateY(-{{LoginToSigInDifference}}px)",
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            transform: "*",
            opacity: 1
          }))
      ], { params: { LoginToSigInDifference: 380 } }),
    ]),
    trigger('loginSubmit', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.Login, style({
        display: '*'
      })),
      transition(`${Modes.Login} => ${Modes.SignIn}`, [
        animate('0.5s',
          style({
            transform: "translateY({{LoginToSigInDifference}}px)",
            opacity: 0
          }))
      ], { params: { LoginToSigInDifference: 380 } }),
      transition(`${Modes.Login} => ${Modes.Guest}, ${Modes.Login} => ${Modes.PasswordRecovery}`, [
        animate('0.5s',
          style({
            transform: "translateY(-{{LoginToOthersDifference}}px)",
            opacity: 0
          }))
      ], { params: { LoginToOthersDifference: 130 } }),
      transition(`${Modes.SignIn} => ${Modes.Login}`, [
        style({
          transform: "translateY({{LoginToSigInDifference}}px)",
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            transform: "*",
            opacity: 1
          })),
      ], { params: { LoginToSigInDifference: 380 } }),
      transition(`${Modes.Guest} => ${Modes.Login}, ${Modes.PasswordRecovery} => ${Modes.Login}`, [
        style({
          transform: "translateY(-{{LoginToOthersDifference}}px)",
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            transform: "*",
            opacity: 1
          }))
      ], { params: { LoginToOthersDifference: 130 } })
    ]),
    trigger('guestSubmit', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.Guest, style({
        display: '*'
      })),
      transition(`${Modes.Guest} => ${Modes.Login}`, [
        animate('0.5s',
          style({
            transform: "translateY({{LoginToOthersDifference}}px)",
            opacity: 0
          }))
      ], { params: { LoginToOthersDifference: 130 } }),
      transition(`${Modes.Login} => ${Modes.Guest}`, [
        style({
          transform: "translateY({{LoginToOthersDifference}}px)",
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            transform: "*",
            opacity: 1
          }))
      ], { params: { LoginToOthersDifference: 130 } })
    ]),
    trigger('recoveryPasswordSubmit', [
      state('*', style({
        display: 'none'
      })),
      state(Modes.PasswordRecovery, style({
        display: '*'
      })),
      transition(`${Modes.PasswordRecovery} => ${Modes.Login}`, [
        animate('0.5s',
          style({
            transform: "translateY({{LoginToOthersDifference}}px)",
            opacity: 0
          }))
      ], { params: { LoginToOthersDifference: 130 } }),
      transition(`${Modes.Login} => ${Modes.PasswordRecovery}`, [
        style({
          transform: "translateY({{LoginToOthersDifference}}px)",
          opacity: 0,
          display: '*'
        }),
        animate('0.5s',
          style({
            transform: "*",
            opacity: 1
          }))
      ], { params: { LoginToOthersDifference: 130 } }),
    ]),
  ]
})

export class LoginPage implements OnInit {

  public modes = Modes;
  public genders = Gender;
  @Input() mode: Modes;
  @Input() allowBack: boolean;
  @Input() allowGuest: boolean;
  @Input() message: string;

  loginForm: FormGroup;
  signInForm: FormGroup;
  guestForm: FormGroup;
  recoveryPasswordForm: FormGroup;

  errorMessages: string[] = [];

  socialSubscriber: Subscription = null;

  LoginToSigInDifference: number = 380;
  LoginToOthersDifference: number = 130;

  constructor(
    private modalController: ModalController,
    public principal: PrincipalService,
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private authService: SocialAuthService,
    private log: LogService,
    private platform: Platform,
    private loadding: LoadingService,
    private localization: LocalizationService

  ) {
    if (platform.is(Platforms.IOS)) {
      console.log("ios");
      this.LoginToSigInDifference = 380;
      this.LoginToOthersDifference = 130;
    }

    if (platform.is(Platforms.Android)) {
      console.log("android");
      this.LoginToSigInDifference = 325;
      this.LoginToOthersDifference = 115;
    }

    if (this.mode == null) {
      this.mode = Modes.Login;
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.signInForm = this.formBuilder.group({
      username: ['', Validators.compose([
        Validators.minLength(4),
        Validators.maxLength(20),
        Validators.required
      ])],
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(Patterns.Email)
      ])],
      passwordRetry: this.formBuilder.group({
        password: ['', Validators.compose([
          Validators.minLength(6),
          Validators.required
        ])],
        passwordConfirmation: ['', Validators.required]
      }, { validator: matchValidator('passwordConfirmation', 'password') }),
      gender: [''],
      birthday: [''],
    });

    this.guestForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.recoveryPasswordForm = this.formBuilder.group({
      usernameOrEmail: ['', Validators.required]
    });
  }

  ngOnInit() {
    history.pushState(modalState, null);
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  changeMode(mode: Modes) {
    this.errorMessages = [];
    this.mode = mode;
  }

  async login() {
    if (this.loginForm.valid) {
      try {
        await this.principal.login(this.loginForm.value.username, this.loginForm.value.password);

        this.back();
      }
      catch (error) {
        console.log(error);
        if (error instanceof Errors) {
          this.errorMessages = error.messages;
        }
        else {
          this.errorMessages = [error.message];
        }
      }
    }
    else {
      Object.keys(this.loginForm.controls).forEach(field => {
        const control = this.loginForm.get(field);
        control.markAsDirty({ onlySelf: true });
      });
    }
  }

  async loginWithFacebook() {
    const statusConnected: string = "connected";
    const facebookCancelled = "User cancelled."
    const facebookCancelledDialogCode = "4201"
    const facebookDidntAuthorized = "User cancelled login or did not fully authorize."

    //Los plugins no funcionan en el PWA, por eso se usa un método alternativo
    //https://www.npmjs.com/package/angularx-social-login/v/2.2.1
    //Se usa la version 2.2.1 ya que la 3.x requiere Angular 9 o 10 y la 2.3.1 da problemas      

    this.loadding.show();

    try {
      let socialUser = await this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);

      await this.principal.loginWithFacebook(socialUser.authToken);

      this.modalController.dismiss();
    }
    catch (error) {
      this.log.error(error);

      if (error != facebookDidntAuthorized) {

        if (error instanceof Errors) {
          this.errorMessages = error.messages;
        }
        else if (!isNullorEmpty(error.message)) {
          this.errorMessages = [error.message];
        } else {
          this.errorMessages = [this.localization.get("ACCOUNT.FACEBOOKLOGINERROR")]
        }
      }
      else{
        this.errorMessages = [];
      }
    }
    finally {
      this.loadding.hide();
    }
  }

  async loginWithGoogle() {
    const googlePlusCancelled = "User cancelled login or did not fully authorize."
    //https://developers.google.com/android/reference/com/google/android/gms/auth/api/signin/GoogleSignInStatusCodes
    const signInCancelled = 12501;
    //https://developers.google.com/android/reference/com/google/android/gms/common/api/CommonStatusCodes.html#ERROR
    const googlePlusError = 13;
    //https://developers.google.com/identity/sign-in/web/reference#error_codes_2
    const popupclosed = "popup_closed_by_user";

    //Los plugins no funcionan en el PWA, por eso se usa un método alternativo
    //https://www.npmjs.com/package/angularx-social-login/v/2.2.1
    //Se usa la version 2.2.1 ya que la 3.x requiere Angular 9 o 10 y la 2.3.1 da problemas

    this.loadding.show();

    try {
      let socialUser = await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

      await this.principal.loginWithGoogle(socialUser.authToken);

      this.modalController.dismiss();
    }
    catch (error) {
      this.log.error(error);

      if (error != googlePlusCancelled && error != signInCancelled && error != googlePlusError && error.error != popupclosed) {
        if (error instanceof Errors) {
          this.errorMessages = error.messages;
        }
        else if (!isNullorEmpty(error.message)) {
          this.errorMessages = [error.message];
        } else {
          this.errorMessages = [this.localization.get("ACCOUNT.GOOGLELOGINERROR")]
        }
      }
      else{
        this.errorMessages = [];
      }
    }
    finally {
      this.loadding.hide();
    }
  }

  async sigin() {

    if (this.signInForm.valid) {
      try {
        let values = this.signInForm.value;

        await this.principal.signIn(values.username, values.name, values.lastName, values.email, values.passwordRetry.password, values.gender, values.birthday);

        const toast = await this.toastController.create({
          message: this.localization.get('ACCOUNT.CREATED'),
          position: 'top',
          color: 'success',
          duration: 3000
        });

        toast.present();

        this.back();
      }
      catch (error) {
        console.log(error);
        if (error instanceof Errors) {
          this.errorMessages = error.messages;
        }
        else {
          this.errorMessages = [error.message];
        }
      }
    }
    else {
      Object.keys(this.signInForm.controls).forEach(field => {
        const control = this.signInForm.get(field);

        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach(field => {
            const innerControl = control.get(field);

            innerControl.markAsDirty({ onlySelf: true });
          });
        }

        control.markAsDirty({ onlySelf: true });
      });

      console.log(this.signInForm);
    }
  }

  loginAsGuest() {
    if (this.guestForm.valid) {
      this.principal.loginAsGuest(this.guestForm.value.name);

      this.back();
    }
    else {
      Object.keys(this.guestForm.controls).forEach(field => {
        const control = this.guestForm.get(field);
        control.markAsDirty({ onlySelf: true });
      });
    }
  }

  async recoveryPassword() {
    //TODO: seria genial cambiarlo para que sea un código enviado por mensaje de texto

    let toast;

    if (this.recoveryPasswordForm.valid) {
      try {
        await this.principal.recoveryPassword(this.recoveryPasswordForm.value.usernameOrEmail);

        toast = await this.toastController.create({
          message: this.localization.get('RECOVERYPASSWORD.SUCCESS'),
          position: 'top',
          color: 'success',
          duration: 5000
        });

        this.changeMode(Modes.Login);
      }
      catch (error) {
        this.log.error(error);

        toast = await this.toastController.create({
          message: this.localization.get('RECOVERYPASSWORD.ERROR'),
          position: 'top',
          color: 'danger',
          duration: 5000
        });
      }

      toast.present();
    }
    else {
      Object.keys(this.recoveryPasswordForm.controls).forEach(field => {
        const control = this.recoveryPasswordForm.get(field);
        control.markAsDirty({ onlySelf: true });
      });
    }
  }

  @HostListener('window:popstate')
  pop() {
    if (this.allowBack){
      this.back();
    }
    else{
      //vuelve a agregar el estado al history
      history.pushState(modalState, null);
    }
  }
  
  back() {
      this.modalController.dismiss();
  }
}
