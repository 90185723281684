import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { isNullOrUndefined } from 'util';
import { environment } from "../../../environments/environment";
import { Platforms } from '../../common';
import { Cancellation, Contact, DeliveryPrice, ExpressDetail, ExpressRequest, Identification, ItemDetail, ItemRate, ItemRequest, LoginRequest, LoginResponse, MobileError, MobileEvent, Notification, Place, PlaceRate, Province, RateDetail, Register, RegisterGuestName, RequestType, Reserve, ReserveDetails, Settings, SingInRequest, TableInfo, UpdateRequest, UserInformation, Wallet } from './entities';


export enum ApiUrls {
  IsAlive = "system/isalive",
  Settings = "system/settings",
  REGISTERDEVICE = "device/identify",
  REGISTERTOKEN = "device/registertoken",
  Identify = "table/identify",
  ValidateLocation = "table/validate",
  REQUESTWAITER = "table/requestwaiter",
  REQUESTCHECK = "table/requestcheck",
  REQUESTITEM = "table/requestitem",
  CANCELREQUEST = "table/requestcancelation",
  RATE = "table/ratetable",
  LOGEVENT = "system/logmobileevent",
  LOGERROR = "system/logmobileError",
  GETLASTITEMS = "table/getlasteditemrequested",
  CANCELITEM = "table/itemrequestcancelation",
  registerUser = "account/register",
  RecoveryPassword = "account/recoverypassword",
  ChangePassword = "account/changepassword",
  LOGIN = "token",
  LOGOUT = "account/logout",
  GETNOTIFICATIONS = "device/getpendingnotifications",
  NOTIFICATIONVIEWED = "device/notificationviewed",
  USERINFORMATION = "account/userinformation",
  REGISTERGUESTNAME = "account/registerguestname",
  PendingAssistance = "table/PendingAssistance",
  EditUser = "account/edit",
  PendingRates = "rate/pending",
  RateItem = "rate/item",
  RatePlace = "rate/place",
  Reserve = "place/reserve",
  Places = "place/all",
  Reserves = "reserve/all",
  CancelReserve = "reserve/cancel",
  PlaceDetails = "place/details",
  DeliveryPrice = "place/deliveryprice",
  Express = "place/express",
  Expresses = "express/all",
  CancelExpress = "express/cancel",
  TransactionCode = "express/transactioncode",
  Locations = "assets/data/locations.json",
  Wallet = "account/wallets"
}

export const SilentHeader: string = "X-Silent";
export const NoLogHeader: string = "X-NoLog";

@Injectable({
  providedIn: 'root'
})
export class ProxyService {
  constructor(
    private http: HttpClient,
    private platform: Platform) { }

  private Silent(millisecons: number = null) {
    let headers: HttpHeaders = new HttpHeaders();

    if (millisecons != null) {
      return headers.append(SilentHeader, millisecons.toString());
    }
    else {
      return headers.append(SilentHeader, "");
    }
  }

  private NoLog(headers: HttpHeaders){
    if (isNullOrUndefined(headers)){
      let headers: HttpHeaders = new HttpHeaders();
    }    

    return headers.append(NoLogHeader, "");
  }

  async isAlive() {
    return this.http.get<Settings>(`${environment.api.server}/${ApiUrls.IsAlive}`, { headers: this.Silent() }).toPromise();
  }

  async Settings(deviceId: number): Promise<Settings> {
    let params = new HttpParams().set('DeviceId', deviceId.toString());

    return this.http.get<Settings>(`${environment.api.server}/${ApiUrls.Settings}`, { params: params }).toPromise();
  }

  async Register(identifier: string): Promise<Register> {
    let platform = "";

    if (this.platform.is(Platforms.Android)) {
      platform = "android";
    } else if (this.platform.is(Platforms.IOS)) {
      platform = "ios";
    } else {
      platform = "pwa";
    }

    return this.http.get<Register>(`${environment.api.server}/${ApiUrls.REGISTERDEVICE}/${platform}/${identifier}`, { headers: this.Silent(), withCredentials: true }).toPromise();
  }

  SingIn(data: SingInRequest) {
    return this.http.post(`${environment.api.server}/${ApiUrls.registerUser}`, data).toPromise();
  }

  UpdateUser(data: UpdateRequest) {
    return this.http.post(`${environment.api.server}/${ApiUrls.EditUser}`, data).toPromise();
  }

  Login(data, silent: boolean = false) {
    const params = new HttpParams({ fromObject: data });

    let headers = {};

    if (silent || data.grant_type == LoginRequest.accessToken_grant) {
      headers = this.Silent();
    }

    return this.http.post<LoginResponse>(`${environment.api.server}/${ApiUrls.LOGIN}`, params, { headers: headers, withCredentials: true });
  }

  Logout(){
    return this.http.post(`${environment.api.server}/${ApiUrls.LOGOUT}`, null, { withCredentials: true }).toPromise();
  }

  UserInformation(uuid) {
    let params = new HttpParams().set('DeviceId', uuid);

    return this.http.get<UserInformation>(`${environment.api.server}/${ApiUrls.USERINFORMATION}`, { headers: this.Silent(), params: params }).toPromise();
  }

  RegisterGuestName(deviceId: number, name: string) {
    let data = new RegisterGuestName();

    data.DeviceId = deviceId;
    data.GuestName = name;

    return this.http.post(`${environment.api.server}/${ApiUrls.REGISTERGUESTNAME}`, data, { headers: this.Silent() }).toPromise();
  }

  RecoveryPassword(usernameOrEmail) {
    return this.http.post(`${environment.api.server}/${ApiUrls.RecoveryPassword}`, { UsernameOrEmail: usernameOrEmail }).toPromise();
  }

  ChangePassword(oldPassword: string, newPassword: string) {
    return this.http.post(`${environment.api.server}/${ApiUrls.ChangePassword}`, { OldPassword: oldPassword, NewPassword: newPassword }).toPromise();
  }

  Identify(code, accuracy, latitude, longitude, localization) {
    const params = new HttpParams()
      .set('localization', localization)
      .set("Accuracy", accuracy)
      .set("Latitude", latitude)
      .set("Longitude", longitude)

    return this.http.get<TableInfo>(`${environment.api.middleware}/${ApiUrls.Identify}/${code}`, { params: params }).toPromise();
  }

  ValidateLocation(code, accuracy, latitude, longitude) {
    const params = new HttpParams()
      .set("Accuracy", accuracy)
      .set("Latitude", latitude)
      .set("Longitude", longitude)

    return this.http.get<boolean>(`${environment.api.server}/${ApiUrls.ValidateLocation}/${code}`, { params: params, headers: this.Silent() }).toPromise();
  }

  WaiterRequest(identification: Identification) {
    return this.http.post(`${environment.api.server}/${ApiUrls.REQUESTWAITER}`, identification, { headers: this.Silent(1000) }).toPromise();
  }

  CheckRequest(identification: Identification) {
    return this.http.post(`${environment.api.server}/${ApiUrls.REQUESTCHECK}`, identification, { headers: this.Silent(1000) }).toPromise();
  }

  CancelRequest(identification: Identification) {
    return this.http.post(`${environment.api.server}/${ApiUrls.CANCELREQUEST}`, identification, { headers: this.Silent(1000) }).toPromise();
  }

  ItemRequest(request: ItemRequest) {
    return this.http.post<ItemDetail[]>(`${environment.api.server}/${ApiUrls.REQUESTITEM}`, request, { headers: this.Silent(2000) }).toPromise();
  }

  CancelItems(cancellation: Cancellation) {
    return this.http.post<ItemDetail[]>(`${environment.api.server}/${ApiUrls.CANCELITEM}`, cancellation).toPromise();
  }

  LastItems(identification: Identification, localization: string) {
    const params = new HttpParams()
      .set('localization', localization)

    return this.http.post<ItemDetail[]>(`${environment.api.server}/${ApiUrls.GETLASTITEMS}`, identification, { headers: this.Silent(), params: params }).toPromise();
  }

  PendingAssistance(tableId: number, silent: boolean) {
    let params = new HttpParams().set('TableId', tableId.toString());

    let headers = {};

    if (silent) {
      headers = this.Silent();
    }
    return this.http.get<RequestType>(`${environment.api.server}/${ApiUrls.PendingAssistance}`, { headers: headers, params: params }).toPromise();
  }

  PendingRates(localization: string) {
    const params = new HttpParams()
      .set('localization', localization)

    return this.http.get<RateDetail[]>(`${environment.api.server}/${ApiUrls.PendingRates}`, { headers: this.Silent(), params: params }).toPromise();
  }

  RateItem(requestId: number, title: string, stars: any, comment: any) {
    let data = new ItemRate();

    data.RequestId = requestId;
    data.Stars = stars;
    data.Comment = comment;
    data.Title = title;

    return this.http.post(`${environment.api.server}/${ApiUrls.RateItem}`, data, { headers: this.Silent() }).toPromise();

  }

  RatePlace(placeId: number, title: string, stars: any, comment: any) {
    let data = new PlaceRate();

    data.PlaceId = placeId;
    data.Stars = stars;
    data.Comment = comment;
    data.Title = title;

    return this.http.post(`${environment.api.server}/${ApiUrls.RatePlace}`, data, { headers: this.Silent() }).toPromise();

  }

  Reserve(placeId: number, date: Date, name: string, phone: string, persons: number) {
    let data = new Reserve();

    data.PlaceId = placeId;
    data.Date = date;
    data.Contact = new Contact();
    data.Contact.Name = name;
    data.Contact.Phone = phone;
    data.Persons = persons;

    return this.http.post(`${environment.api.server}/${ApiUrls.Reserve}`, data, { headers: this.Silent() }).toPromise();

  }

  Reserves(localization: string) {
    const params = new HttpParams()
      .set('localization', localization)

    return this.http.get<ReserveDetails[]>(`${environment.api.server}/${ApiUrls.Reserves}`, { headers: this.Silent(), params: params }).toPromise();
  }

  CancelReserve(id: number) {
    return this.http.post(`${environment.api.server}/${ApiUrls.CancelReserve}`, { Id: id }, { headers: this.Silent() }).toPromise();
  }

  Places() {
    return this.http.get<Place[]>(`${environment.api.server}/${ApiUrls.Places}`).toPromise();
  }

  PendingNotifications(id: number) {
    return this.http.get<Notification>(`${environment.api.server}/${ApiUrls.GETNOTIFICATIONS}?Id=${id}`, { headers: this.Silent() }).toPromise();
  }

  MarkAsViewed(deviceId: number, notificationId: number) {
    let data = {
      DeviceId: deviceId,
      NotificationId: notificationId
    }

    return this.http.post(`${environment.api.server}/${ApiUrls.NOTIFICATIONVIEWED}`, data, { headers: this.Silent() }).toPromise();

  }

  PlaceDetails(placeId: number, type:string, localization: string) {
    const params = new HttpParams()
    .set('localization', localization)
    .set('type', type)
    .set("placeId", placeId.toString())

    return this.http.get<Place>(`${environment.api.server}/${ApiUrls.PlaceDetails}`, { params: params }).toPromise();
  }

  TransactionCode() {
    return this.http.get<string>(`${environment.api.server}/${ApiUrls.TransactionCode}`).toPromise();
  }

  DeliveryPrice(placeId: number, latitude: number, longitude: number) {
    const params = new HttpParams()
      .set("placeId", placeId.toString())
      .set("latitude", latitude.toString())
      .set("longitude", longitude.toString())

    return this.http.get<DeliveryPrice>(`${environment.api.server}/${ApiUrls.DeliveryPrice}`, { headers: this.Silent(), params: params }).toPromise();
  }

  Express(express: ExpressRequest) {
    return this.http.post(`${environment.api.server}/${ApiUrls.Express}`, express).toPromise();
  }

  Expresses(localization: string) {
    const params = new HttpParams()
      .set('localization', localization)

    return this.http.get<ExpressDetail[]>(`${environment.api.server}/${ApiUrls.Expresses}`, { headers: this.Silent(), params: params }).toPromise();
  }

  CancelExpress(id: number) {
    return this.http.post(`${environment.api.server}/${ApiUrls.CancelExpress}`, { Id: id }, { headers: this.Silent() }).toPromise();
  }

  Provinces(){
    return this.http.get<Province[]>(`${ApiUrls.Locations}`, { headers: this.Silent() }).toPromise();
  }

  Wallets(){
    return this.http.get<Wallet[]>(`${environment.api.server}/${ApiUrls.Wallet}`, { headers: this.Silent() }).toPromise();
  }

  LogEvent(data: MobileEvent) {
    return this.http.post(`${environment.api.server}/${ApiUrls.LOGEVENT}`, data, { headers: this.NoLog(this.Silent()) });
  }

  LogError(data: MobileError) {
    return this.http.post(`${environment.api.server}/${ApiUrls.LOGERROR}`, data, { headers: this.NoLog(this.Silent()) });
  }
}

